<template>
  <div class="profile">
    <div v-if="loading" class="preloader"></div>
    
    <!-- <div class="search">
      <button class="search_btn">
        <img src="../assets/List/Vector.svg" alt="иконка поиска">
      </button>
      <input class="search_input" type="text" placeholder="See all links...">
    </div> -->

    <div v-else>
      <div class="media">
        <p class="Media">Social Media</p>
      </div>
      <div class="block">

        <div v-for="item in socials" :key="item.value" class="social" @click="openModal(item.value)">
          <div class="img">
            <icon :name="item.value" class="mainpic" />
          </div>
          <p class="labelSocial">{{ item.value }}</p>
        </div>

      </div>
      <div class="pay">
        <p class="payments">Payments</p>
      </div>
      <div class="block2">
        <div v-for="item in payments" :key="item.value" class="social" @click="openModal(item.value)">
          <div class="img">
            <icon :name="item.value" class="mainpic" />
          </div>
          <p class="labelSocial">{{ item.value }}</p>
        </div>

      </div>
      <div class="Portfolio">
        <p class="portfolio">Portfolio</p>
      </div>
      <div class="block3">
        <div v-for="item in portfolio" :key="item.value" class="social" @click="openModal(item.value)">
          <div class="img">
            <icon :name="item.value" class="mainpic" />
          </div>
          <p class="labelSocial">{{ item.value }}</p>
        </div>
      </div>
      <div class="Custom">
        <p class="custom">Custom</p>
      </div>
      <div class="block4">
        <div class="social" @click="openModal('Custom Link')">
          <div class="img">
            <img class="pic" src="../assets/img/custom.svg">
          </div>
          <p class="labelSocial">Custom Link</p>
        </div>
        <!-- <div class="social" @click="openModal('File')">
          <div class="img">
            <img class="pic" src="../assets/img/file.svg">
          </div>
          <p class="labelSocial">File</p>
        </div> -->
      </div>
      <modal :show_dialog="showDialog" :tags="tags" :statusData="statusData" :arr="arr" :socialData="socialValue"
        @close="showDialog = false" @save-complete="complete" />
    </div>
    
  </div>
</template>
<script>
import {message} from 'ant-design-vue'
import axios from '/src/axios/axios'
import Modal from '../components/ModalWindow.vue'
import icon from '../components/SetIconVue.vue'
export default {
  components: { 
    Modal, 
    icon 
  },
  data() {
    return {
      loading: true,
      showDialog: false,
      key: '',
      socialValue: null,
      Inst: '',
      src: '',
      tags: '',
      arr: '',
      statusData: '',
      socials: [
        { value: "Instagram" },
        { value: 'Linkedln' },
        { value: 'Skype' },
        { value: 'Snapchat' },
        { value: 'Signal' },
        { value: 'Facebook' },
        { value: 'X' },
        { value: 'Facetime' },
        { value: 'Address' },
        { value: 'Tiktok' },
        { value: 'Spotify' },
        { value: 'Twitch' },
        { value: 'Vimeo' },
        { value: 'Messenger'},
        { value: 'YouTube' },
        { value: 'Pinterest' },
        { value: 'WhatsApp' },
        { value: 'Telegram' },
        { value: 'Email' }
      ],
      payments: [
        { value: 'Paypal' },
        { value: 'AmazonPay' },
        { value: 'Binance' },
        // { value: 'Tether'}
      ],
      portfolio: [
        { value: "Behance" },
        { value: 'Dribble' }
      ]
    }
  },
  async mounted() {

    if (!this.$store.state.user) this.$router.push('/login')

    this.tags = this.$route.query
    console.log(9999999, this.tags)
    this.loading = true
    try{
      await axios.post('/api/tag/check/', this.$route.query)

      .then(response => {
        this.arr = response.data
      })
      .catch(err => {
        console.error(err)
      })
    }
    catch(e){
      throw new Error
    }

    this.statusData = this.$store.state.user
    this.loading = false
  },

  methods: {
    goBack() {
      this.$router.go(-1)
    },

    search() {
      alert('asdad');
    },

    openModal(value) {
      this.Message = value;
      this.socialValue = value;
      this.showDialog = true;
    },

    closeModal() {
      this.modalVisible = false;
    },

    complete() {
      message.success('Success!');
      window.location.reload()

    }
  }
}

</script>
<style scoped>
.profile {
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
  align-items: center;
}

.back {
  position: absolute;
  left: 30px;
  top: 2px;
}

.search_input {
  border: none;
  background: none;
  padding-left: 50px;
  padding-top: 20px;
  width: 80%;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #8A8A8A;
}

.search_input:focus-visible {
  outline: none;
}

.search_btn {
  position: absolute;
  display: block;
  border: none;
  background: none;
  left: 25px;
  top: 110px;
}

.search {
  display: inline-block;
  width: 325px;
  height: 55px;
  background: #F4F4F4;
  border-radius: 10px;
}

.Media {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #222;
}

.block {
  margin-top: 15px;
  position: relative;
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
}

.social {
  display: block;
  background: #F4F4F4;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-right: 6px;
  padding-left: 6px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  margin-bottom: 20px;
}

.pic {
  width: 80px;
  height: 70px;
  padding: 10px;
  border-radius: 7px;
}

.labelSocial {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 12.95px;
  line-height: 16px;
  text-align: center;
  color: #222222;
}

.pay {
  margin-top: 48px;
  margin-top: 39px;
}

.payments {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #222222;
}

.block2 {
  margin-left: 28px;
  margin-top: 17px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.Portfolio {
  margin-top: 52px;
}

.portfolio {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #222222;
}

.block3 {
  margin-left: 28px;
  margin-top: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.Custom {
  margin-top: 52px;
}

.custom {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #222222;
}

.block4 {
  margin-left: 28px;
  margin-top: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.img {
  width: 80px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainpic {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader {
  border: 4px solid #363636;
  border-top: 4px solid #72D48E;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 120px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>