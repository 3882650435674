<template>
  <div class="login">
    <div class="h">
      <img class="mas" src="../assets/MAS.svg">
    </div>
    <div class="block">
      <div class="p">
        <p class="text">Enter 4 digit code to confirm it’s you</p>
      </div>
      <div class="input">
        <input class="inp1" v-model="code[0]" @input="moveToNext(0)" maxlength="1" ref="inp1">
        <input class="inp2" v-model="code[1]" @input="moveToNext(1)" maxlength="1" ref="inp2">
        <input class="inp3" v-model="code[2]" @input="moveToNext(2)" maxlength="1" ref="inp3">
        <input class="inp4" v-model="code[3]" @input="moveToNext(3)" maxlength="1" ref="inp4">
      </div>
      <div class="resend">
        <p class="res">Resend OTP</p>
      </div>
      <div class="button">
        <button @click="press" class="btn">Verify</button>
      </div>
      <div class="dots">
        <img class="slider1" src="../assets/Login2/Rectangle272.svg">
        <img class="slider2" src="../assets/Login2/Rectangle271.svg">
        <img class="slider3" src="../assets/Login2/Rectangle273.svg">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted(){
    if(!this.$store.state.user){
      this.$router.push('/login')
    }
  },
  data() {
    return {
      code: ['', '', '', ''] // массив для хранения значений в каждом <input>
    }
  },
  methods: {
    moveToNext(index) {
      if (this.code[index].length === 1 && index < 3) {
        this.$refs[`inp${index + 2}`].focus(); // переключение фокуса на следующий <input>
      }
    },
    press() {
      this.$router.push('/signup'); // просто переход на вторую страницу
    }
  }
}
</script>

<style scoped>

.login{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}
.h{
  position: relative;
  margin-top: 239px;
  align-items: center;
  
}

.block{
  position: relative;
  margin-top: 210px;
  
}
.input{
  position:relative;
margin-top:18px;
display:flex;
display: block;
padding-right: 8px;
}

.inp1{
  position: relative;
  width: 55px;
  height: 48px;
  background: #F4F4F4;
  border:none;
  border-radius: 5.46512px;
  margin-left:8px;
  margin-right:8px;
  padding-left:25px;
  outline: none;
}
.inp2{
  position: relative;
  width: 55px;
  height: 48px;
  background: #F4F4F4;
  border:none;
  border-radius: 5.46512px;
  margin-left:22px;
  padding-left:25px;
  outline: none;
}
.inp3{
  position: relative;
  width: 55px;
  height: 48px;
  background: #F4F4F4;
  border:none;
  border-radius: 5.46512px;
  margin-left:22px;
  padding-left:25px;
  outline: none;
}

.inp4{
  position: relative;
  width: 55px;
  height: 48px;
  background: #F4F4F4;
  border:none;
  border-radius: 5.46512px;
  margin-left:22px;
  padding-left:25px;
  outline: none;
}


.text{
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */

text-align: center;

/* 222222 */

color: #222222;
}


.resend{
  display: inline-block;
  margin-left:220px;
}

.res{
  padding-top:10px;
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 15px;
text-align: right;

/* 222222 */

color: #222222;
}

.button{
  margin:40px;
}

.btn{
width: 173.4px;
height: 45.63px;
background: #363636;
border-radius: 8px;
border:none;
color:#FFFFFF;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
text-transform: capitalize;
box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
}

.dots{
  position: relative;
  padding:10px;
}

.slider2{
  margin-left:8px;
}

.slider3{
  margin-left:8px;
}
</style>