<template>
    <div class="profile">
        <a-divider>Email</a-divider>
        <a-input class="input" type="email" @input="isEmailChanged" v-model:value="email.new" placeholder="Email" />
        <a-button v-if="email.changed" :disabled="pass.disabled" class="save" @click="changeEmail">Save</a-button>
        
        <a-divider>Password</a-divider>
        <a-button class="btn_pass" type="dashed" @click="pass.hidden = !pass.hidden">Change password</a-button>
        <div v-if="!pass.hidden" class="password_input">
            <a-input-password @blur="onPasswordInput" class="input" v-model:value="pass.old" placeholder="Old password" />
            <a-input-password @blur="onPasswordInput" class="input" v-model:value="pass.password" placeholder="New password" min-length="8" />
            <a-input-password @blur="onPasswordInput" class="input" v-model:value="pass.repeat_password" placeholder="Repeat new password" min-length="8" />
            <a-button :disabled="isChangePasswordDisabled" class="save" @click="changePassword">Save</a-button>
        </div> 
    </div>
</template>
<script>
import axios from '/src/axios/axios'
import {message} from 'ant-design-vue'
export default {
    data(){
        return {
            isChangePasswordDisabled: true, // Изначально кнопка отключена
            email: {
                new: '',
                changed: false
            },
            pass: {
                passwordsMatch: false,
                hidden: true,
                old: '',
                password: '',
                repeat_password: ''
            }
        }
    },
    methods: {
        disabledPasswordBtn(){
            return !this.pass.passwordsMatch && !this.pass.password.length < 8 && !this.spass.repeat_password.length < 8
        },

        changeEmail(){
            try{
                axios.post('/api/user/email/', {action: 'change', uid: this.$store.state.user._id, val: this.email.new})
                .then(response => {
                    if(!response.data) message.error('Invalid email. Try another email.')
                    else message.success('Please go to your email inbox to confirm.')
                    console.log(response)
                })
                .catch(err => {
                    console.error(err)
                })
            }
            catch(err){
                console.error(err)
            }
        },

        onPasswordInput(event) {
            const password = event.target.value
            if (password.length < 8) {
                this.$message.error('Password must be at least 8 characters long.')
            } else {
                this.checkPasswordsMatch()
            }
        },
       
        checkPasswordsMatch() {
            return this.pass.passwordsMatch = this.pass.password === this.pass.repeat_password
        },

        isEmailChanged() {
            return this.email.changed = this.email.new !== this.$store.state.user.email
        },

        changePassword() {
            if (this.pass.password.length > 7) {
                axios.post('/api/user/change_pass/', { 
                    uid: this.$store.state.user._id, 
                    password: this.pass.old, 
                    new_pass: this.pass.password
                })
                .then(response => {
                    console.log(response.data)
                    if (!response.data) this.$message.error('Your old password is incorrect')
                    else this.$message.success('Password successfully changed!')
                })
                .catch(err => {
                    this.$message.error('Try again')
                    console.error(err)
                })
            } else this.$message.error('Please fix the errors and try again.')
        },
    },
    computed: {
        isConfirmPasswordMatch() {
            return this.pass.password === this.pass.repeat_password && this.pass.password.length > 7
        },
    },
    watch: {
        // Обновлять состояние кнопки в зависимости от условий
        isConfirmPasswordMatch: {
            handler(newValue) {
                console.log(newValue)
                this.isChangePasswordDisabled = !newValue;
            },
            immediate: true, // Проверить совпадение при загрузке
        },
    },
    created(){
        if(this.$store.state.user) this.email.new = this.$store.state.user.email
    }
}
</script>
<style scoped>
.profile{
    padding: 0 20px;
}
.password_input{
    margin-bottom: 30px;
}
.input{
    margin-top: 15px;
}
.save{
    margin-top: 15px;
}
.btn_pass{
    margin-top: 15px;

}
</style>