<template>
  <div class="pay">
    <h1 class="payment">Payment</h1>
    <div class="line">
      <LineBlack />
    </div>
    <div class="input-wrapper">
      <label for="cardName" class="labelName">Name on card</label>
      <input id="cardName" v-model="cardName" class="cardname" />

      <div class="num">
        <label for="cardNum" class="labelNum">Card number</label>
        <input id="cardNum" v-model="cardNum" class="cardNum" />
      </div>

      <div class="zip">
        <label for="zipCode" class="labelZip">ZIP/Postal code</label>
        <input id="zipCode" v-model="zipCode" class="zipCode" />
      </div>

      <div class="dualInput">
        <label for="expireDate" class="labelExpireDate">Expire Date</label>
        <input id="expireDate" placeholder="MM/YY" v-model="expireDate" class="expireDate" />

        <label for="security" class="labelSecurity">Security code</label>
        <input id="security" v-model="security" class="Security" />
      </div>
    </div>
    <div>
      <GoBack />
    </div>
  </div>
</template>

<script>
import LineBlack from '../components/LineBlack.vue'
import GoBack from '../components/GoBack.vue'

export default {
  components: {
    LineBlack,
    GoBack
  },
  mounted(){
    if(!this.$store.state.user){
        this.$router.push('/login')
      }
  },
  data() {
    return {
      expireDate: '',
      zipCode: '',
      security:'',
      cardNum:'',
      cardName:''
    }
  },
  watch: {
    expireDate(newValue) {
      // Numbers
      const sanitizedValue = newValue.replace(/\D/g, '');
      
      // Add / after 2 simbols
      const formattedValue = sanitizedValue.replace(/(\d{2})(\d{0,2})/, (match, p1, p2) => {
        if (p2) {
          return p1 + '/' + p2;
        } else {
          return p1;
        }
      });
      
      // Limit symbols
      this.expireDate = formattedValue.slice(0, 5);
    }
  }
}
  
  
</script>

<style scoped>
.input-wrapper {
  
  
  margin-top: 2em;
}
.payment {
    display: inline block;
    margin-top: 2em;
    font-size: 1.5em;
  }

  .input-wrapper{
    align-items: center;
    position: relative;
  }
  .cardname {
  position: relative;
  width: 9em;
  height: 1.2em;
  top: 8em; 
  border-radius: 5px;
  border: 1.5px solid #a5a0a0;
}
.labelName {
  position: fixed;
  font-size: 0.9em;
  top:21em;
}

.cardNum{
  position: relative;
  width: 9em;
  height: 1.2em;
  border-radius: 5px;
  top:10em;
  border: 1.5px solid #a5a0a0;
}

.labelNum{
  position: fixed;
  font-size: 0.9em;
  top:27em;
}

.zipCode{
  position: relative;
  width: 9em;
  height: 1.2em;
  border-radius: 5px;
  top:16em;
  border: 1.5px solid #a5a0a0;
}
.labelZip{
  position: fixed;
  font-size: 0.9em;
  top:39.8em;
}


.labelExpireDate{
  position: fixed;
  font-size: 0.9em;
  top:33.3em;
}
.expireDate{
  
  position: relative;
  width: 4.5em;
  height: 1.2em;
  border-radius: 5px;
  top:11em;
  border: 1.5px solid #a5a0a0;
  
}

.expireDate::placeholder{
  font-size: 0.7em;
  padding-left:0.7em;
}

.labelSecurity{
  position: fixed;
  font-size: 0.9em;
  top:33.3em;
}

.Security{
  position: relative;
  width: 4.5em;
  height: 1.2em;
  border-radius: 5px;
  top:11em;
  border: 1.5px solid #a5a0a0;
}

</style>