<template>
    <div>
        <section id="first">
            <div class="container">
                <a-row>
                    <a-col :span="12" :xs="24">
                        <div id="logo">
                            <img src="../assets/img/Logo.svg" alt="logo">
                        </div>
                    </a-col>
                    <a-col :span="12" :xs="24">
                        <div class="buttons">
                            <a href="/login" class="login">Login</a>
                            <a href="/signup" class="signup">Sign Up</a>
                        </div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="14" :xs="24">
                        <h1>All contacts in 
                            <span>one touch</span>
                        </h1>
                        <div class="round"></div>
                        <div class="shine">
                            <svg xmlns="http://www.w3.org/2000/svg" width="278" height="264" viewBox="0 0 278 264" fill="none">
                                <path d="M226.91 263.526C254.287 267.136 279.977 247.684 277.606 220.172C275.807 199.306 271.165 178.706 263.746 158.955C250.439 123.528 233.368 85.5944 204.944 60.6096C176.52 35.6249 141.529 12.5028 104.687 3.8496C83.3963 -1.15121 60.2765 0.883506 37.4931 5.36778C10.3986 10.7005 -5.29742 38.9617 1.79358 65.6499L4.62795 76.3176C11.7189 103.006 39.7759 117.77 67.0402 122.152C69.836 122.602 72.6201 123.151 75.3872 123.801C94.4697 128.283 112.154 137.398 126.877 150.339C141.599 163.28 152.907 179.649 159.8 197.999C160.799 200.66 161.701 203.351 162.505 206.066C170.349 232.543 188.589 258.474 215.967 262.084L226.91 263.526Z" fill="#7DD797"/>
                            </svg>
                        </div>
                        <div class="dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="272" height="197" viewBox="0 0 272 197" fill="none">
                                <path d="M238.773 195.338C262.127 190.764 277.718 167.884 268.88 145.789C262.7 130.337 254.848 115.561 245.437 101.733C227.918 75.9915 205.363 54.0692 179.134 37.2903C152.905 20.5114 123.546 9.22423 92.8319 4.11086C72.3798 0.705949 51.6095 0.085562 31.0817 2.22888C12.9104 4.12616 0.435219 20.3966 0.435219 38.6667C0.435219 64.3526 25.0273 85.3677 50.6881 86.5045C61.4655 86.982 71.5197 87.9265 78.6795 89.1185C97.9031 92.3189 116.278 99.3833 132.695 109.885C149.111 120.387 163.228 134.108 174.193 150.219C177.045 154.41 179.669 158.74 182.056 163.191C193.304 184.162 215.42 199.913 238.773 195.338Z" fill="#7DD797" fill-opacity="0.4"/>
                            </svg>
                        </div>
                        <p>
                            Say goodbye to paper business cards. Instead, share your contact information with a single tap using <span>MAS</span> digital business cards.
                        </p>
                        <a href="/login" class="get">Login</a>

                        <div class="stars">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="40" viewBox="0 0 43 40" fill="none">
                            <path d="M21.4349 0L27.6144 13.3063L42.179 15.0715L31.4336 25.0604L34.2555 39.4576L21.4349 32.3248L8.61437 39.4576L11.4363 25.0604L0.690828 15.0715L15.2554 13.3063L21.4349 0Z" fill="#FFDC5F"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="40" viewBox="0 0 43 40" fill="none">
                            <path d="M21.4349 0L27.6144 13.3063L42.179 15.0715L31.4336 25.0604L34.2555 39.4576L21.4349 32.3248L8.61437 39.4576L11.4363 25.0604L0.690828 15.0715L15.2554 13.3063L21.4349 0Z" fill="#FFDC5F"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="40" viewBox="0 0 43 40" fill="none">
                            <path d="M21.4349 0L27.6144 13.3063L42.179 15.0715L31.4336 25.0604L34.2555 39.4576L21.4349 32.3248L8.61437 39.4576L11.4363 25.0604L0.690828 15.0715L15.2554 13.3063L21.4349 0Z" fill="#FFDC5F"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="40" viewBox="0 0 43 40" fill="none">
                            <path d="M21.4349 0L27.6144 13.3063L42.179 15.0715L31.4336 25.0604L34.2555 39.4576L21.4349 32.3248L8.61437 39.4576L11.4363 25.0604L0.690828 15.0715L15.2554 13.3063L21.4349 0Z" fill="#FFDC5F"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="40" viewBox="0 0 43 40" fill="none">
                            <path d="M21.4349 0L27.6144 13.3063L42.179 15.0715L31.4336 25.0604L34.2555 39.4576L21.4349 32.3248L8.61437 39.4576L11.4363 25.0604L0.690828 15.0715L15.2554 13.3063L21.4349 0Z" fill="#FFDC5F"/>
                            </svg>
                        </div>
                    </a-col>
                    <a-col class="ip_container" :span="24" :xs="24">
                        <!-- <img class="iphone1" src="../assets/img/iPhone14Pro.png" alt="iphone"> -->
                        <!-- <img class="iphone2" src="../assets/img/iPhone14ProMax.png" alt="iphone2"> -->
                        <img class="iphone3" src="../assets/img/ip3.png" alt="ip3">
                    </a-col>
                </a-row>
            </div>
        </section>
        <section id="video">
            <div class="container">
                <a-row>
                    <a-col :span="16" :xs="24">
                        <div class="light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="630" height="512" viewBox="0 0 630 512" fill="none">
                                <path d="M170.893 50.5894C303.649 -41.6252 514.103 -0.966783 606.317 131.789C698.532 264.546 494.165 232.65 361.409 324.865C228.653 417.08 129.589 597.028 37.3749 464.272C-54.8397 331.516 38.1364 142.804 170.893 50.5894Z" fill="url(#paint0_linear_9_4)" fill-opacity="0.7"/>
                                <defs>
                                    <linearGradient id="paint0_linear_9_4" x1="20.0393" y1="423.239" x2="552.065" y2="53.6855" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#7DD797"/>
                                    <stop offset="1" stop-color="#BCE9C9"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div class="dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="439" height="332" viewBox="0 0 439 332" fill="none">
                                <path d="M158.26 8.95578C259.744 -15.8181 411.154 14.4255 435.928 115.909C460.702 217.393 310.927 193.848 209.443 218.622C107.96 243.396 29.7142 399.359 4.94038 297.875C-19.8335 196.392 56.7765 33.7296 158.26 8.95578Z" fill="#444444"/>
                            </svg>
                        </div>
                    </a-col>
                    <a-col :span="8" :xs="24">
                        <h2>What is an NFC business card?</h2>
                        <p>An NFC business card is an electronic business card that opens when you tap your phone to it and provides contact information about its owner.</p>
                        <a href="https://instagram.com/mastag.online" class="get">Get MAS</a>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24" :xs="24">
                        <img class="img_video" src="../assets/img/image7.png" alt="video">
                    </a-col>
                </a-row>
            </div>
        </section>
        <section id="share">
            <div class="container">
                <a-row>
                    <a-col :span="18" :xs="24">
                        <h2>
                            With <span class="m">M</span><span class="as">AS</span>, you can share anything.
                        </h2>
                        <div class="describe">
                            <div class="light">
                                <svg xmlns="http://www.w3.org/2000/svg" width="586" height="362" viewBox="0 0 586 362" fill="none">
                                    <path d="M259.838 3.3029C405.957 -17.6246 561.105 101.719 582.033 247.838C602.96 393.957 533.566 322.692 387.448 343.619C241.329 364.547 24.0875 393.957 3.15991 247.838C-17.7676 101.72 113.719 24.2304 259.838 3.3029Z" fill="url(#paint0_linear_9_56)" fill-opacity="0.4"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_9_56" x1="-23.3314" y1="260.645" x2="562.246" y2="176.777" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#7DD797"/>
                                        <stop offset="0.142225" stop-color="#8ADBA1"/>
                                        <stop offset="0.677083" stop-color="#BBE9C8"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div class="item">
                                <img src="../assets/img/image15.png" alt="social">
                                <div class="heading">Social media</div>
                                <p>Choose what you want to share and start connecting where it matters most!</p>
                            </div>
                            <div class="item">
                                <img src="../assets/img/image14.png" alt="pay">
                                <div class="heading">Payment links</div>
                                <p>Split the bill with a single tap! You can safely add your payment apps to your profile to make sharing them easy.</p>
                            </div>
                            <div class="item">
                                <img src="../assets/img/image11.png" alt="contact">
                                <div class="heading">Custom links</div>
                                <p>Create your own profile and choose what to share. Over 25 networks are supported for sharing custom links.</p>
                            </div>
                            <div class="item">
                                <img src="../assets/img/image16.png" alt="calendar">
                                <div class="heading">Contact information</div>
                                <p>Custom link fields allow you to share any URL you like, from calendars to menus and everything in between.</p>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="6" :xs="24">
                        <img class="socs" src="../assets/img/soc.png" alt="soc">
                        <!-- <img class="socs" src="../assets/img/socs2.png" alt="soc2"> -->
                    </a-col>
                </a-row>
            </div>
        </section>
        <section id="shop">
            <div class="container">
                <a-row>
                    <a-col span="24" :xs="24">
                        <h2>Bestsellers <span class="m">M</span><span class="as">AS</span></h2>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col span="24" :xs="24">
                        <div class="purchases">
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/16.jpg" alt="item">
                                </div>
                                <div class="name">DS-12</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/15.jpg" alt="item">
                                </div>
                                <div class="name">DS-04</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/14.jpg" alt="item">
                                </div>
                                <div class="name">DS-10</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/13.jpg" alt="item">
                                </div>
                                <div class="name">DS-28</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/12.jpg" alt="item">
                                </div>
                                <div class="name">DS-11</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/11.jpg" alt="item">
                                </div>
                                <div class="name">DS-31</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/10.jpg" alt="item">
                                </div>
                                <div class="name">DS-32</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/9.jpg" alt="item">
                                </div>
                                <div class="name">DS-29</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/8.jpg" alt="item">
                                </div>
                                <div class="name">DS-01</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/7.jpg" alt="item">
                                </div>
                                <div class="name">DS-06</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/6.jpg" alt="item">
                                </div>
                                <div class="name">DS-07</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/5.jpg" alt="item">
                                </div>
                                <div class="name">DS-02</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/4.jpg" alt="item">
                                </div>
                                <div class="name">DS-24</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/3.jpg" alt="item">
                                </div>
                                <div class="name">DS-36</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/2.jpg" alt="item">
                                </div>
                                <div class="name">DS-35</div>
                                <div class="price">$19.99</div>
                            </div>
                            <div class="item">
                                <div class="image">
                                    <img src="../assets/shop/1.jpg" alt="item">
                                </div>
                                <div class="name">DS-20</div>
                                <div class="price">$19.99</div>
                            </div>
                        </div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col span="24" :xs="24">
                        <a href="https://instagram.com/mastag.online" class="get">Get MAS</a>
                    </a-col>
                </a-row>
            </div>
        </section>
        <section id="cabinet">
            <div class="container">
                <a-row>
                    <a-col :xs="24" span="12">
                        <h2> <span>M</span>AS
                            <br>
                            <div>Personal account</div>
                        </h2>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col class="imgs" :xs="24">
                        <img class="iphone1" src="../assets/img/iPhone14Pro.png" alt="iphone">
                        <img class="iphone2" src="../assets/img/iPhone14ProMax.png" alt="iphone2">
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :xs="24">
                        <a class="get" href="/login">Login</a>
                    </a-col>
                </a-row>
            </div>
        </section>
        <section id="footer">
            <div class="container">
                <a-row>
                    <a-col :xs="24">
                        <ul class="menu">
                            <li><a href="/about">About</a></li>
                            <li><a href="/help">Help</a></li>
                        </ul>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :xs="24">
                        <div class="copyright">&copy; {{ currentYear }} MAStag.online. All right reserved.</div>
                    </a-col>
                </a-row>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data(){
        return{
            currentYear: new Date().getFullYear()
        }
    },
    mounted(){

    }
}
</script>
<style scoped>

.container{
    max-width: 1200px;
    margin: 0 auto;
}
#first{
    background: #2A2A2A;
    position: relative;
}
.socs{
    width: 120%;
    position: absolute;
    top: -40px;
    z-index: -1;
    left: 0;
}
.login{
    display: inline-block;
    padding: 23px;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 25.14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.508px;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 192px;
}
.round{
    border-radius: 50%;
    height: 260px;
    position: absolute;
    width: 260px;
    background: #FFF;
    top: 225px;
    left: -28px;
    z-index: 0;
}
#logo{
    margin-top: 25px;
    display: flex;
}
.buttons{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}
.signup{
    border-radius: 23.401px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%), #72D48E;
    padding: 23.401px 35.102px;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 25.14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.508px;
    cursor: pointer;
}
h1{
    text-align: left;
    position: relative;
    z-index: 10;
    margin-top: 290px;
    margin-bottom: 89px;
    font-family: Roboto;
    font-size: 79.14px;
    font-style: normal;
    font-weight: 300;
    line-height: 76px; /* 96.032% */
    letter-spacing: 3.166px;
    background: linear-gradient(90deg, #5CCB4A -14.37%, #30C3CC 52.84%, #1E2FC9 124.38%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
h1 span{
    background: linear-gradient(90deg, #5CCB4A -14.37%, #30C3CC 52.84%, #1E2FC9 124.38%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Roboto;
    font-size: 79.14px;
    font-style: normal;
    font-weight: 900;
    line-height: 76px;
    letter-spacing: 3.166px;
}
.shine{
    position: absolute;
    top: 0;
    left: 100px;
}
.dark{
    position: absolute;
    top: 100px;
    left: 66px;
}
#first p{
    color: #FFF;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    text-align: left;
    margin-bottom: 30px;

}
#first p span{
    color: #97DFAB;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}
.get{
    color: #FFF;
    text-align: center;
    cursor: pointer;
    font-family: Roboto;
    font-size: 30.14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.808px;
    display: inline-flex;
    padding: 23.401px 35.102px;
    align-items: flex-start;
    gap: 11.701px;
    border-radius: 23.401px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%), #72D48E;
}

.iphone1{
    position: absolute;
    left: 0;
    top: 165px;
    width: 480px;
}
.iphone2{
    position: absolute;
    top: 68px;
    z-index: 500;
}
.iphone3{
    width: 150%;
    position: absolute;
    left: -126px;
    top: -75px;
}
.ip_container{
    height: 500px;
}
.stars{
    margin-top: 28px;
    margin-bottom: 103px;
}
.stars svg{
    margin-left: 9px;
}
#video{
    position: relative;
    background: #F3F3F3;
    padding-bottom: 90px;
}
#video h2{
    margin-top: 250px;
    color: #000;
    /* font-family: Inter; */
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
#video p{
    color: #000;
    text-align: left;
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 50px;
}
#video .get{
    text-transform: none;
    margin-bottom: 56px;
    border: none;
background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%), #72D48E;
}
#video .dark{
    position: absolute;
    left: 0;
    top: 320px;
}
#video .light{
    position: absolute;
    top: 143px;
    left: -100px;
}
#share{
    position: relative;
}
#share h2{
    margin-top: 87px;
    color: #000;
    font-family: Roboto;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 123px;
    text-align: left;
}
#share .m{
    color: #7DD797;
    font-family: Roboto;
    font-size: 54px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
#share .as{
    color: #000;
    font-family: Roboto;
    font-size: 54px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.describe{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
}
.describe .item{
    max-width: 250px;
    margin-right: 98px;
    margin-bottom: 57px;
}
.heading{
    color: #000;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 6px;
    margin-bottom: 12px;
}
#share .light{
    position: absolute;
    z-index: -1;
    top: -100px;
    left: -235px;
}

#share .get{
    margin-bottom: 100px;
    display: block;
    width: max-content;
}

#shop{
    background: #F3F3F3;
}

#shop h2{
    color: #000;
    font-family: Roboto;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 48px;
    margin-bottom: 50px;
    text-align: center;
}

#shop h2 span.m{
    color: #7DD797;
    font-family: Roboto;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
#shop h2 span.as{
    color: #000;
font-family: Roboto;
font-size: 54px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

#shop .purchases{
display: flex;
flex-wrap: wrap;
justify-content: center;
}
#shop .purchases .item{
    margin-top: 21px;
    margin-right: 9px;
    margin-left: 9px;
}
#shop .purchases .item .name{
    color: var(--222222, #222);
text-align: center;
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 12px;
margin-bottom: 2px;
    
}
#shop .purchases .item .price{
    color: var(--222222, #222);
font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
#shop .purchases .item .image{
    width: 188px;
    border-radius: 10px;
    background: #FFF;
    height: 188px;
}
#shop .get{
    margin-top: 50px;
    margin-bottom: 70px;
}
.container{
    position:relative;
    margin-left:auto;
    margin-right:auto;
    padding-right:15px;
    padding-left:15px;
}
#cabinet h2{
    margin-top: 50px;
    font-size: 39px;
    text-align: right;
    font-family: Roboto;
    font-weight: 700;
    font-family: Roboto;
    text-align: center;
}
#cabinet h2 span{
    color: #7DD797;
}
#cabinet .iphone1{
    top: -50px;
    left: -45px;
}
#cabinet .get{
    margin-bottom: 50px;
}
#footer{
    background: #404040;
    color: #fff;
    padding: 20px 0;
}
#footer .menu{
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
}
#footer .menu li{
    font-family: Roboto;
    font-size: 21px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
    
}
#footer .menu li a{
    color: #fff;
    text-decoration: underline;
}
@media (min-width: 350px){
    .container{
        overflow: hidden;
    }
    #logo{
        justify-content: center;
        margin-bottom: 50px;
    }
    .buttons{
        justify-content: center;
        display: none;
    }
    .signup{
        padding: 23px;
        background: none;
    }
    h1{
        font-size: 50px;
    }
    h1 span {
        font-size: 50px;
    }
    .iphone1{
        position: relative;
        top: 0;
        width: 350px;
        top: -60px;
    }
    .iphone2{
        top: 68px;
        width: 350px;
        left: 100px;
    }
    .imgs{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .img_video{
        max-width: 100%;

    }
    #video h2{
        margin-top: 100px;
        text-align: left;
    }
    .get{
        padding: 20px;
    }
    #share h2{
        font-size: 39px;
    }
    .describe .item{
        margin-right: 0;
        max-width: 100%;
    }
    .socs{
        width: 100%;
        height: 450px;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        max-width: 400px;
        transition: transform 0.3s ease;
        transform: translateX(20px);
        position: relative;
        width: 100%;
        left: -15px;
        opacity: .8;
        top: 0;
    }
    #shop .purchases .item{
        max-width: 40%;
        overflow: hidden;
    }
    #shop .purchases .item .image{
        width: 100%;
        height: auto;
        border-radius: 20px;
        overflow: hidden;
    }
    #shop .purchases .item .image img{
        width: 100%;
    }
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
  
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px;
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px;
    width:960px;
    max-width:100%;
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px;
    width:1140px;
    max-width:100%;
  }
}

@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}
</style>