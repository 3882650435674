<template>
  <div>
    <router-view  />
  </div>
</template>

<script>
import ClientJS from 'clientjs'
export default{
  data(){
    return {
      fingerprint: null
    }
  },
  mounted(){
    let data = this.$store.state

    if(!data && window.location.pathname.includes('/tag')){
      this.$router.push('/login')
    } 

    // let a = this.generateFingerprint()
    // console.log(87, a)
  },
 
  methods: {
    generateFingerprint() {
      const client = new ClientJS();
      const fingerprint = client.getFingerprint();
      this.fingerprint = fingerprint;
    }
  }
}
</script>
<style>
body{
  overflow-x: hidden;
}
p{
  margin:0;
  padding:0;
}
.ant-modal-content{
  border-radius:25px;
}
.ant-dropdown-menu{
  border-radius:10px;
}
.preloader {
  border: 4px solid #363636;
  border-top: 4px solid #72D48E;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 120px auto;
}
.select .ant-select-selector{
  background-color: #f4f4f4;
}
@font-face {
    font-family: 'Gemunu Libre';
    src: 
      url('./assets/fonts/GemunuLibre-Bold.woff2') format('woff2'),
      url('./assets/fonts/GemunuLibre-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
  
    font-family: 'Outfit';
    src: url('./assets/fonts/Outfit-Regular.eot');
    src: url('./assets/fonts/Outfit-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Outfit-Regular.woff2') format('woff2'),
      url('./assets/fonts/Outfit-Regular.woff') format('woff'),
      url('./assets/fonts/Outfit-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Outfit';
    src: url('./assets/fonts/Outfit-Medium.eot');
    src: url('./assets/fonts/Outfit-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Outfit-Medium.woff2') format('woff2'),
        url('./assets/fonts/Outfit-Medium.woff') format('woff'),
        url('./assets/fonts/Outfit-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Medium.eot');
    src: url('./assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Roboto-Medium.woff2') format('woff2'),
        url('./assets/fonts/Roboto-Medium.woff') format('woff'),
        url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Bold.eot');
    src: url('./assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Roboto-Bold.woff2') format('woff2'),
        url('./assets/fonts/Roboto-Bold.woff') format('woff'),
        url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Outfit';
    src: url('./assets/fonts/Outfit-Bold.eot');
    src: url('./assets/fonts/Outfit-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Outfit-Bold.woff2') format('woff2'),
        url('./assets/fonts/Outfit-Bold.woff') format('woff'),
        url('./assets/fonts/Outfit-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
body{ 
  margin: 0;
  padding:0;
}
#app {
  margin:0;
  padding:0;
  font-family: 'Outfit Medium';
  font-family: 'Outfit Bold';
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #132863;
}
</style>