<template>
  <div class="nfc">
    <div class="h">
      <img class="back" src="../assets/Profile/Back.svg" @click="goBack">
      <p>NFC tag disign</p>
    </div>
    <div class="block">
      <div class="product">
        <img class="prod" src="../assets/img/disign1.svg" alt="товар">
      </div>
    </div>
    <div class="block2">
      <div class="priceBtn">
        <p class="price" data-price="99">$10.<span class="small-decimal"></span></p>
        <button class="buy" @click="buy">Buy</button>
      </div>
    </div>
    <div>
      <OverLay/>
    </div>
  </div>
</template>
  
<script>
  import OverLay from '../components/OverLay.vue'
  export default {
    components:{
      OverLay
    },
    mounted(){
      if(!this.$store.state.user){
        this.$router.push('/login')
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1); // Переход на предыдущую страницу
      },
      buy() {
        this.$router.push('/ShopOrder')
      }
    }
  }
</script>
  
<style scoped>   
body {
  margin: 0;
  padding: 0;
}

.nfc{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.back{
  position: absolute;
  left:30px;
  top:2px;
}

.h{
  margin-top: 70px;
  margin-bottom: 15px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  position: relative;
  align-items: center;
  text-align: center;
  color: #222222;
  padding-bottom: 15px;
}
.h:after{
  background: url('../assets/List/Vector4.svg');
  content: '';
  width: 325px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 162.5px);
}

.block{
  margin-top:28px;
  display:block;
}

.product{
  margin-left:50px;
  margin-top:60px;
}

.block2{
  display: inline-block;
  margin-top:82px;
}

.priceBtn{
  display: flex;
  align-items: center;
}

.price{
  flex: 1;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 33.5938px;
  line-height: 42px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.price::after {
  content: attr(data-price);
  font-size: 12px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 42px;
  color: #000000;
}
.buy{
  position: relative;
  display: inline-block;
  margin-left:129px;
  width:92px;
  height: 34px;
  background: #72D58E;
  border:none;
  border-radius: 4px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>