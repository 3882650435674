<template>
  <div>
    <h3>Select tags</h3>
    <a-select
      v-model:value="selectedTags"
      mode="multiple"
      placeholder="Select tags"
      style="width: calc(100% - 30px);margin-bottom: 30px;"
      show-search
      @change="getMetric"
    >
      <a-select-option v-for="tag in tags" :key="tag.url" :value="tag.url">{{ tag.short_url ? tag.short_url : '...' + tag.url.substring(tag.url.length - 4) }}</a-select-option>
    </a-select>
    <Line style="margin-bottom: 50px;" v-if="chart.data.labels.length && chart.data.datasets.length" :data="chart.data" :options="chart.options" />
    
    <div v-if="load.length">
      <h3>Visitors</h3>
      <div  class="visitor" v-for="item in load" :key="item._id">
        <div class="datetime item">
          {{ formatDateTime(item.datetime) }}
        </div>
        <div class="useragent item">
          <icon :name="getBrowser(item.userAgent)"/>
          <icon :name="getOperatingSystem(item.userAgent)" />
        </div>
        <div class="ip item">{{ item.ip }}</div>
        <div class="page item">{{ item.page.length > 10 ? '...' + item.page.slice(-4) : item.page.replace('/tag/','') }}</div>
      </div>
    </div>
    <a-empty v-else />
  </div>
</template>
<script>
import axios from '/src/axios/axios'
import icon from '../components/SetIconVue.vue'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
export default {
    components: {
      Line,
      icon
    },
    data() {
      return {
        selectedTags: [],
        tags: [],
        chart: {
          data:{
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true
              }
            }
          },
          chartKey: 0
        },
        load: []
      }
    },
    mounted() {
      this.getTags()
      this.getLabels()
    },
    methods: {
      formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        return `${day}.${month}.${year}, ${hours}:${minutes}`
      },
      getBrowser(userAgentString) {
        const browsers = [
            { name: 'Chrome', regex: /Chrome\/([\d.]+)/ },
            { name: 'Firefox', regex: /Firefox\/([\d.]+)/ },
            { name: 'Safari', regex: /Safari\/([\d.]+)/ },
            // Добавьте другие браузеры и их регулярные выражения по необходимости
        ]

        const browserInfo = browsers.find(browser => userAgentString.match(browser.regex))
        return browserInfo ? browserInfo.name : 'Unknown Browser'
      },

      getOperatingSystem(userAgentString) {
        const operatingSystems = [
            { name: 'Windows', regex: /Windows NT ([\d.]+)/ },
            { name: 'Mac OS', regex: /Mac OS X ([\d._]+)/ },
            { name: 'iOS', regex: /iPhone OS ([\d._]+)/ },
            { name: 'Android', regex: /Android ([\d.]+)/ },
            // Добавьте другие операционные системы и их регулярные выражения по необходимости
        ]

        const osInfo = operatingSystems.find(os => userAgentString.match(os.regex))
        return osInfo ? osInfo.name : 'Unknown OS'
      },
      async getMetric(){
        let get_pages = JSON.parse(JSON.stringify(this.selectedTags))
        this.tags.forEach(item => {
          this.selectedTags.forEach(element => {
            if(item.url == element) get_pages.push(item.short_url)
          })
        })
   
        await axios.post('/api/metric/get', {tags: get_pages, uid: this.$store.state.user._id})
        .then(res => {
          
          const visitsPerTag = res.data.reduce((acc, item) => {
            let shortPage = item.page.length > 10 ? '...' + item.page.slice(-4) : item.page;
            shortPage = shortPage.replace('/tag/', '');
            if (!acc[shortPage]) {
              acc[shortPage] = {} // Создаем объект для каждой страницы
            }

            const dateKey = item.datetime.split('T')[0] // Получаем ключ - дату в формате 'YYYY-MM-DD'
            acc[shortPage][dateKey] = acc[shortPage][dateKey] ? acc[shortPage][dateKey] + 1 : 1 // Увеличиваем счетчик визитов для каждой даты на определенной странице
            return acc
          }, {})

          // Формирование данных для Chart.js
          const datasets = Object.keys(visitsPerTag).map((page) => {
            const pageData = visitsPerTag[page]
         
            const pageVisitsData = Object.keys(pageData).map(date => pageData[date])
            const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`
            return {
              label: page,
              data: pageVisitsData,
              backgroundColor: color,
              borderColor: color,
              borderWidth: 1
            }
          })

          // Формирование меток оси X (даты)
          const datesSet = new Set();
          Object.values(visitsPerTag).forEach(pageData => {
            Object.keys(pageData).forEach(date => datesSet.add(date));
          });
          const labels = Array.from(datesSet);

          res.data.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
          this.load = res.data
          this.chart.data.labels = labels
          this.chart.data.datasets = datasets

        })
        .catch(err => console.error(err))
      },
      async getTags() {
        await axios.post('/api/tag/list/', this.$store.state.user)
        .then(response => {
          let result = []
          response.data.forEach(element => {
            if(element.short_url) result.push({short_url: element.short_url, url: element.url})
            else result.push({url: element.url})
          })
          this.tags = result
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
      },
      getLabels(){
        // last 60 days
        const currentDate = new Date();
        const sixtyDaysAgo = new Date();
        sixtyDaysAgo.setDate(currentDate.getDate() - 60);
        
        for (let i = 0; i <= 60; i++) {
          const date = new Date()
          date.setDate(sixtyDaysAgo.getDate() + i)
          this.chart.data.labels.push(date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }))
        }
      },
      updateChart(data) {
        this.chart.data.datasets[0].data = data
        this.chart.chartKey++
      },
      // Функция для получения пути к иконке браузера на основе его названия
      getBrowserIcon(browserName) {
        // Логика определения пути к иконке браузера
        // Например:
        if (browserName === 'Chrome') {
          return 'путь_к_иконке_для_Chrome';
        } else if (browserName === 'Firefox') {
          return 'путь_к_иконке_для_Firefox';
        }
        // И так далее для других браузеров
      },

      // Функция для получения пути к иконке операционной системы на основе её названия
      getOSIcon(osName) {
        // Логика определения пути к иконке операционной системы
        // Например:
        if (osName === 'Windows') {
          return 'путь_к_иконке_для_Windows';
        } else if (osName === 'Mac OS') {
          return 'путь_к_иконке_для_Mac_OS';
        }
        // И так далее для других операционных систем
      }
    }
}
</script>
<style scoped>
.visitor{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0; /* Расстояние между элементами */
  height: 60px; /* Высота контейнера */
  background: #e9e9e9;
  border-radius: 10px;
  margin: 15px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.useragent{
  display: flex
}
.datetime {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.ip {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.useragent {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.page {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}
.icon{
  margin-right: 10px;
  margin-left: 10px;
}
</style>