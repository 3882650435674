import { createApp } from 'vue'
import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      user: null,
      location: {}
    }
  },
  mutations: {
    increment (state, userData) {
      state.user = userData
      localStorage.setItem('myData', JSON.stringify(userData))
    },
    removeUser(state) {
      state.user = null
    },
    setLoc(state, location){
      state.location = location
    }
  }
})

const savedData = localStorage.getItem('myData');
if (savedData) {
  store.commit('increment', JSON.parse(savedData))
}

const app = createApp({ /* your root component */ })

// Install the store instance as a plugin
app.use(store)

export default store