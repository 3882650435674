<template>
  <div class="overlay">
    <div class="elements">
      
      <div class="tags" :class="{ active: current == 'tags' }" @click="$router.push('/list')">
        <svg xmlns="http://www.w3.org/2000/svg" width="25px" viewBox="-20 0 630 500">
          <path d="M392.9 32.43C400.6 31.1 408.6 32.89 414.1 37.41C498.2 96.14 544 173.7 544 255.1C544 338.2 498.2 415.9 414.1 474.6C409.3 478.6 402.4 480.5 395.5 479.9C388.5 479.3 382 476.3 377.1 471.4L193.7 288.7C188.1 283.2 185 275.7 184.1 267.8C184.1 260 188.1 252.5 193.6 246.9C199.2 241.4 206.7 238.2 214.5 238.2C222.4 238.2 229.9 241.3 235.4 246.8L400.5 411.2C455.1 366.5 484.8 312 484.8 255.1C484.8 193.5 447.9 132.9 380.9 85.76C374.5 81.24 370.1 74.35 368.8 66.62C367.4 58.89 369.2 50.94 373.8 44.53C378.3 38.12 385.2 33.77 392.9 32.43V32.43zM186.9 479.6C179.2 480.9 171.3 479.1 164.8 474.6C81.67 415.9 35.84 338.2 35.84 255.1C35.84 173.7 81.67 96.14 164.8 37.41C170.5 33.4 177.4 31.53 184.4 32.12C191.3 32.71 197.8 35.72 202.7 40.63L386.1 223.3C391.7 228.8 394.8 236.3 394.8 244.2C394.9 251.1 391.8 259.5 386.2 265.1C380.7 270.6 373.2 273.8 365.3 273.8C357.5 273.8 349.1 270.7 344.4 265.2L179.3 100.7C124.7 145.9 95.03 199.9 95.03 255.1C95.03 318.5 131.9 379.1 198.1 426.2C205.4 430.8 209.7 437.6 211.1 445.4C212.4 453.1 210.6 461.1 206.1 467.5C201.6 473.9 194.7 478.2 186.9 479.6V479.6z" fill="#8A8A8A"/>
        </svg>
        <div class="labeltags">
          <p class="labeltags">Tags</p>
        </div>
      </div>

      <div class="metric" :class="{ active: current == 'analytics' }" @click="$router.push('/analytics')" >
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="-1 0 24 24" id="meteor-icon-kit__regular-analytics" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 15C2.44772 15 2 15.4477 2 16V21C2 21.5523 2.44772 22 3 22C3.55228 22 4 21.5523 4 21V16C4 15.4477 3.55228 15 3 15zM3 13C4.65685 13 6 14.3431 6 16V21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21V16C0 14.3431 1.34315 13 3 13zM11 0C12.6569 0 14 1.34315 14 3V21C14 22.6569 12.6569 24 11 24C9.3431 24 8 22.6569 8 21V3C8 1.34315 9.3431 0 11 0zM11 2C10.4477 2 10 2.44772 10 3V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V3C12 2.44772 11.5523 2 11 2zM19 7C20.6569 7 22 8.34315 22 10V21C22 22.6569 20.6569 24 19 24C17.3431 24 16 22.6569 16 21V10C16 8.34315 17.3431 7 19 7zM19 9C18.4477 9 18 9.44771 18 10V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V10C20 9.44771 19.5523 9 19 9z" fill="#758CA3"/>
        </svg>
        <div class="labelmetric">
          <p class="labelshop">Analytics</p>
        </div>
      </div>

      <div class="profile" :class="{ active: current == 'profile' }" @click="$router.push('/profile')" >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
          <defs></defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Dribbble-Light-Preview" transform="translate(-420.000000, -2159.000000)" fill="#000000">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                      <path d="M374,2009 C371.794,2009 370,2007.206 370,2005 C370,2002.794 371.794,2001 374,2001 C376.206,2001 378,2002.794 378,2005 C378,2007.206 376.206,2009 374,2009 M377.758,2009.673 C379.124,2008.574 380,2006.89 380,2005 C380,2001.686 377.314,1999 374,1999 C370.686,1999 368,2001.686 368,2005 C368,2006.89 368.876,2008.574 370.242,2009.673 C366.583,2011.048 364,2014.445 364,2019 L366,2019 C366,2014 369.589,2011 374,2011 C378.411,2011 382,2014 382,2019 L384,2019 C384,2014.445 381.417,2011.048 377.758,2009.673" id="profile-[#1335]"></path>
                  </g>
              </g>
          </g>
        </svg>
        <div class="labelorder">
          <p class="labelorder">Profile</p>
        </div>
      </div>

      <!-- <div class="order" :class="{ active: current == 'order' }" @click="toorder" >
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.0002 6.79341L3.70373 4.67341H3.70473L8.9992 2.55342L14.2967 4.67341L9.0002 6.79341ZM17.3405 3.73101L9.3724 0.541768C9.1332 0.446038 8.8662 0.446038 8.627 0.541768L0.630581 3.74234C0.197431 3.9155 -0.0125688 4.31528 0.000581187 4.70817V15.3286C0.000631187 15.5289 0.0606712 15.7247 0.172961 15.8906C0.285261 16.0566 0.444671 16.1851 0.630661 16.2596L8.6271 19.4602C8.7793 19.5212 8.9441 19.544 9.1072 19.5265C9.2086 19.5156 9.3074 19.4894 9.4003 19.449L17.3689 16.2596C17.5551 16.1852 17.7147 16.0567 17.8272 15.8908C17.9397 15.7248 17.9999 15.529 18 15.3285V4.67333C18 4.61264 17.9945 4.5523 17.9836 4.49301C17.9331 4.20788 17.7619 3.94217 17.4699 3.78885C17.4281 3.76652 17.3849 3.7472 17.3405 3.73101ZM2.00721 6.15548L4.36766 7.10025L7.997 8.5532V17.0472L2.00721 14.6493V6.15548ZM15.9934 6.15522L10.0036 8.5531V17.0472L15.9934 14.6493V6.15522Z" fill="#8A8A8A"/>
        </svg>
        <div class="labelorder">
          <p class="labelorder">Orders</p>
        </div>
      </div>

      <div class="tags" :class="{ active: current == 'tags' }" @click="totags">
        <svg xmlns="http://www.w3.org/2000/svg" width="25px" viewBox="-20 0 630 500">
          <path d="M392.9 32.43C400.6 31.1 408.6 32.89 414.1 37.41C498.2 96.14 544 173.7 544 255.1C544 338.2 498.2 415.9 414.1 474.6C409.3 478.6 402.4 480.5 395.5 479.9C388.5 479.3 382 476.3 377.1 471.4L193.7 288.7C188.1 283.2 185 275.7 184.1 267.8C184.1 260 188.1 252.5 193.6 246.9C199.2 241.4 206.7 238.2 214.5 238.2C222.4 238.2 229.9 241.3 235.4 246.8L400.5 411.2C455.1 366.5 484.8 312 484.8 255.1C484.8 193.5 447.9 132.9 380.9 85.76C374.5 81.24 370.1 74.35 368.8 66.62C367.4 58.89 369.2 50.94 373.8 44.53C378.3 38.12 385.2 33.77 392.9 32.43V32.43zM186.9 479.6C179.2 480.9 171.3 479.1 164.8 474.6C81.67 415.9 35.84 338.2 35.84 255.1C35.84 173.7 81.67 96.14 164.8 37.41C170.5 33.4 177.4 31.53 184.4 32.12C191.3 32.71 197.8 35.72 202.7 40.63L386.1 223.3C391.7 228.8 394.8 236.3 394.8 244.2C394.9 251.1 391.8 259.5 386.2 265.1C380.7 270.6 373.2 273.8 365.3 273.8C357.5 273.8 349.1 270.7 344.4 265.2L179.3 100.7C124.7 145.9 95.03 199.9 95.03 255.1C95.03 318.5 131.9 379.1 198.1 426.2C205.4 430.8 209.7 437.6 211.1 445.4C212.4 453.1 210.6 461.1 206.1 467.5C201.6 473.9 194.7 478.2 186.9 479.6V479.6z" fill="#8A8A8A"/>
        </svg>
        <div class="labeltags">
          <p class="labeltags">Tags</p>
        </div>
      </div> -->
    
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      current: null,
    }
  },
  watch: {
    '$route.path'(newPath) {
      this.setCurrentFromPath(newPath);
    },
},
  created() {
    this.setCurrentFromPath(this.$route.path);
  },
  methods: {
    setCurrentFromPath(path) {
      if (path === '/shop') {
        this.current = 'cart';
      } else if (path === '/order') {
        this.current = 'order';
      } else if (path === '/list') {
        this.current = 'tags';
      } else if (path === '/profile') {
        this.current = 'profile';
      } else if (path === '/analytics') {
        this.current = 'analytics';
      } else {
        this.current = null;
      }
    },
  }
}


</script>
<style scoped>
.overlay{
  position: fixed;
  left: calc(50% - 178px);
  bottom: 25px;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  z-index: 9;
}

.active svg path{
  fill: #72D48E;
}

.elements{
  display:flex;
  margin-left: 25px;
  justify-content: space-around;
  align-items: center;
  min-width: 330px;
}

.cart{
  position: relative;
  right:95px;
  padding-bottom: 15px;
  padding-top: 10px;
}

.labelshop{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}

.order{
  position: relative;
  right:5px;
  padding-bottom: 15px;
  padding-top: 11px;
}

.labelorder{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}

.tags{
  position: relative;
  padding-bottom: 15px;
  padding-top: 11px;
}

.labeltags{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}
</style>