import { createApp } from 'vue'
// import './registerServiceWorker'
import 'ant-design-vue/dist/antd.css'
import store from './store/index'
import VueCookies from 'vue-cookies'
import App from './App.vue'
// import './assets/main.css'
import router from './router'
import Ant from 'ant-design-vue'
// import VueGtag from "vue-gtag-next"
import 'js-base64'

try{
    let app = createApp(App)
    app.use(store)
    // app.use(VueGtag, {
    //     property: {
    //         id: "G-2VD74FQWYX"
    //     }
    // })
    app.use(router).mount('#app')
    app.use(Ant)
    // app.use(i18n)
    app.use(VueCookies)
} catch(e){
    console.error(e)
}

import '@fortawesome/fontawesome-free/css/all.css'