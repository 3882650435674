<template>
  <div class="divider"></div>
</template>

<script>
    
</script>



<style scoped>

.divider {
  display: block;
  height: 2px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), #585757, rgba(0, 0, 0, 0));
  position: absolute;
  top:7em;
  left: 0;
  right: 0;
}
</style>