<template>
  <div class="shop">
    <div class="block">
      <div v-for="item in this.products" :key="item._id" class="product" @click="todisigne">
        <img class="disigne" src="../assets/img/disigne.svg" alt="product">
        <label class="discription">{{ item.name }}</label>
        <label class="price">${{item.price}}</label>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from '../axios/axios'
  export default {
    mounted(){
      this.getProducts()
    },
    data() {
      return {
        showOverlay: true,
        products: [],
        loading: true
      }
    },
    methods: {
      todisigne() {
        this.$router.push('/NfcTagDesign');
      },
      getProducts(){
        try{
          axios.post('/api/product/list/')
          .then(response => {
            this.products = response.data
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false; // Set loading to false after the API call is done
          });
        }catch(e){
          console.error(e)
        }
      
      }
    }
  }
  </script>
  
<style scoped>
body {
  margin: 0;
  padding: 0;
}
.shop{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.block{
  position: relative;
  display:flex;
  margin-top: 21px;
  flex-wrap: wrap;
  padding-bottom:70px;
}

.product{
  display: flex;
  border-radius: 10px;
  margin-left: 32px;
  margin-bottom: 72px;
  flex-direction: column;
}

.disigne{
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;
  border-radius: 10px;
  width: 138px;
  height: 124px;
  background: #EFEFEF;
}

.discription{
  margin-top:10px;
  margin-right:50px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #222222;
}

.price{
  margin-right:80px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  color: #222222;
}
</style>