<template>
  <div>
    <button @click="goBack" class="back-button">
      <i class="fas fa-chevron-left"></i>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1); // Переход на предыдущую страницу
    }
  }
};
</script>

<style scoped>
.back-button{
  position: fixed;
  top:3.4em;
  left:2em;
  color: #000; /* Цвет стрелочки */
  text-decoration: none;
  font-size: 20px; /* Размер стрелочки */
  border:none;
  background: none;
}
</style>