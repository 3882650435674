<template>
    <a-modal :visible="visible" :closable="false" :footer="null" :width="320"  >
      <div class="h">
        <div class="trash" @click="deletesocial">
        <img class="trashimg" src="../assets/img/trash.svg">
      </div>
          <p>{{  this.namefor }}</p> 
          <img src="../assets/img/line.svg">
      </div>
      <div class="block">
        <div class="icons">
          <div class="mainpic">
            <icon :name="keyobj" class="icc" />
          </div>
          <!-- <p class="customize">Customize icon</p>
          <img class="edit" src="../assets/img/Edit.svg"> -->
          
        </div>
        <div class="inputs">
          <div class="username" >
            <label for="name" class="text">Title</label>
            <input class="inp" id="name" v-model="name">
          </div>
          <div class="link">
            <!-- <label for="link" class="text">Link</label> -->
            <input class="inp" id="link" v-model="link">
          </div>
        </div>
        <div class="buttons">
          <button class="cancel" @click="cancel">Cancel</button>
          <button class="save" @click="save">Save</button>
        </div>
      </div>
    </a-modal>
  </template>
  
  <script>
  import icon from '../components/SetIconVue.vue'
  import axios from '/src/axios/axios';
  export default {
    props: ['show_dialog','socialData','keyobj','dataobj','arr','namefor','linkfor'],
    components: {
      icon
    },
 
    data(){
      return {
        src:'',
        showDialog1: false,
        visible:false,
        title:'',
        name:null,
        link:''
      }
    },
    mounted() {
      this.name = this.namefor;
      this.link = this.linkfor;
    },
    updated(){
      this.link = this.linkfor;
      this.name = this.namefor;
    },
   
    watch: {
      show_dialog: function(val) {
        this.visible = val;
        this.showDialog1 = val;
      }
    },
    
    methods:{
      cancel(){
        this.visible = false
        this.$emit('close')
      },

      deletesocial(){
        axios.post('/api/tag/deletesocial/', {url: this.arr.url, links: this.keyobj})
        this.visible = false;
        this.$emit('close');
        this.$emit('deleteComplete');
      },
      async save(){
        await axios.post('/api/tag/changeinfo/',  {url:this.arr.url,links: { [this.keyobj]: { name: this.name, link: this.link }}})
        this.visible = false;
        this.$emit('close');
        this.$emit('save-complete');
      } 
    }
  }
  </script>
  
  <style scoped>
  .wrapper{
    border-radius: 10px;
  }
  .h{
    margin-bottom: 15px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    position: relative;
    align-items: center;
    text-align: center;
    color: #222222;
    padding-bottom: 15px;
  }
  
  .block{
    display: block;
  }
  
  .icons{
    display: flex;
    padding-left:106px;
    width: 100px;
    height: 100px;
  }

  .inputs{
    margin-top:20px;
  }
  .inp{
    position: relative;
    margin-bottom: 10px;
    width: 250px;
    height: 48px;
    background: #F4F4F4;
    border:none;
    border-radius: 5.46512px;
    margin-left:8px;
    margin-right:8px;
    outline: none;
    padding-right: 15px;
    text-align: right;
  }
  .inp::placeholder{
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #8A8A8A;
  }
  

  .modalWrapClass{
    border-radius: 40px;
    background-color: #f0f0f0;
  }
  
  .cancel{
    width: 125px;
    height: 40px;
    border:none;
    margin-top:60px;
    background: none;
    border: 1px solid #222222;
    border-radius: 5px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
  }
  
  .save{
    width: 125px;
    height: 40px;
    border:none;
    margin-top:80px;
    margin-left:21px;
    background: #50DB78;
    border-radius: 5px;
    border: 1px solid #222222;
    border-radius: 5px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
  }

  .customize{
    position: relative;
    top: 60px;
    right: 84px;
    width: 150px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #222222;
    white-space: nowrap;
  }

  .edit{
    position: absolute;
    left: 215px;
    top: 140px;
  }

  .trash{
    position: absolute;
    margin-left:240px;
    bottom: 44px;
  }
.text{
  position: relative;
  top:34px;
  left:10px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #8A8A8A;
  margin-top: 14px;
  margin-left: 10px;
  z-index: 9999;
}
.icc{
  transform: scale(1.9);
}
</style>