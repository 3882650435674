import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import LoginFirst from '../views/LoginFirst.vue'
import LoginSecond from '../views/LoginSecond.vue'
import SignupUser from '../views/SignupUser.vue'
import ListFirst from '../views/ListFirst.vue'
import MyTag from '../views/MyTag.vue'
import ShopFirst from '../views/ShopFirst'
import NfcTagDesign from '../views/NfcTagDesign'
import ShopOrder from '../views/ShopOrder'
import ShopPay from '../views/ShopPay'
import OrdersPage from '../views/OrdersPage.vue'
import AddNewLinks from '../views/AddNewLinks.vue'
import MordaPhone from '../views/MordaPhone.vue'
import AdminFirst from '../views/AdminFirst.vue'
import AdminProduct from '../views/admin/AdminProducts'
import StartPage from '../views/StartPage.vue'
import AboutUs from '../views/AboutUs.vue'
import ActivatePage from '../views/ActivatePage.vue'
import ResetpassOne from '../views/ResetpassOne.vue'
import ResetpassTwo from '../views/ResetpassTwo.vue'
import HelpPage from '../views/HelpPage.vue'
import IndexLanding from '../views/IndexLanding'
import MyAnalytics from '../views/MyAnalytics.vue'
import NotFound from '../views/NotFound.vue'
import ProfilePage from '../views/ProfilePage.vue'
import axios from '../axios/axios'
const routes = [
  {
    path: '/',
    component: () => import('@/layouts/PagesLayout.vue'),
    children: [
      {
        path: '',
        name: 'IndexLanding',
        component: IndexLanding,
        meta: {
          title: 'MAS - digital business cards',
          metaTags: [
            {
              name: 'description',
              content: 'Create an electronic business card today and start using it to promote your business!'
            },
            {
              property: 'og:description',
              content: 'Create an electronic business card today and start using it to promote your business!'
            },
            {
              property: 'keywords',
              content: 'nfc, mas, business card, digital, phone sticker,'
            }
          ]
        }
      },
      { 
        path: 'tag/:id', 
        component: MordaPhone,
        meta: {
          title: 'MAS | Personal page of user',
          metaTags: [
            {
              name: 'description',
              content: 'Create an electronic business card today and start using it to promote your business!'
            },
            {
              property: 'og:description',
              content: 'Create an electronic business card today and start using it to promote your business!'
            }
          ]
        }
      }
    ],
  },
  {
    path:'/',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'list',
        name:'My MAS',
        component: ListFirst,
        title: 'MAS | Tags'
      },
      {
        path: 'login',
        name: 'Login',
        component: LoginFirst,
        title: 'MAS | Login',
        metaTags: [
          {
            name: 'description',
            content: 'Create an electronic business card today and start using it to promote your business!'
          },
          {
            property: 'og:description',
            content: 'Create an electronic business card today and start using it to promote your business!'
          }
        ]
      },
      {
        path:'LoginSecond',
        name:'Confirm',
        component:LoginSecond,
        title: 'MAS | Confirm',
        metaTags: [
          {
            name: 'description',
            content: 'Create an electronic business card today and start using it to promote your business!'
          },
          {
            property: 'og:description',
            content: 'Create an electronic business card today and start using it to promote your business!'
          }
        ]
      },
      {
        path: 'signup',
        name: 'Sign Up',
        component: SignupUser,
        title: 'MAS | Sign Up',
      },
      {
        path:'MyTag',
        name:'My tag',
        component: MyTag,
        title: 'MAS | My tag',
      },
      {
        path:'analytics',
        name:'Analytics',
        component: MyAnalytics,
        title: 'MAS | Analytics',
      },
      {
        path: 'shop',
        name: 'Shop',
        component: ShopFirst
      },
      {
        path: 'NfcTagDesign',
        name: 'Nfc Tag Design',
        component: NfcTagDesign
      },
      {
        path: 'ShopOrder',
        name: 'Order',
        component: ShopOrder
      },
      {
        path: 'ShopPay',
        name: 'Pay',
        component: ShopPay
      },
      {
        path:'OrdersPage',
        name:'Orders Page',
        component: OrdersPage,
        title: 'MAS | Orders'
      },
      {
        path: 'AddNewLinks',
        name: 'Add New Link',
        component: AddNewLinks,
        title: 'MAS | Add new links'
      },
      {
        path: 'start',
        name: 'Start',
        component: StartPage,
        title: 'MAS | Start',
      },
      {
        path: 'about',
        name: 'About',
        component: AboutUs,
        title: 'MAS | About',
      },
      {
        path: 'activate',
        name: 'Activate',
        component: ActivatePage,
        title: 'MAS | Activate',
      },
      {
        path:'ResetpassOne',
        name:'Reset password',
        component: ResetpassOne,
        title: 'MAS | Reset password',
      },
      {
        path: 'ResetpassTwo',
        name: 'New password',
        component: ResetpassTwo,
        title: 'MAS | New password',
      },
      {
        path: 'help',
        name: 'Help',
        component: HelpPage,
        title: 'MAS | Help',
        metaTags: [
          {
            name: 'description',
            content: 'Create an electronic business card today and start using it to promote your business!'
          },
          {
            property: 'og:description',
            content: 'Create an electronic business card today and start using it to promote your business!'
          }
        ]
      },
      {
        path: 'profile',
        name: 'My Profile',
        component: ProfilePage,
        title: 'MAS | My Profile',
      },
    ]
  },
  {
    path: '/admin',
    component: () => import('@/layouts/AdminLayout.vue'),
    children: [
      {
        path: '',
        name: 'AdminFirst',
        component: AdminFirst,
      },
      {
        path: '/product',
        name:'AdminProduct',
        component: AdminProduct
      },
    ]
  },
  
 
  {
    path: '/:catchAll(.*)', // Ловушка для всех неперехваченных маршрутов
    component: NotFound
  },
  {
    // path: '/about',
    // name: 'about',
    // // route level code-splitting
    // // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();

  const allowedPaths = ['/tag/']
  if (allowedPaths.some(path => to.fullPath.includes(path))) {
    const metricData = {
      userAgent: navigator.userAgent,
      datetime: new Date(),
      page: to.fullPath
    }

    if (store.state.user && store.state.user._id) {
      metricData.uid = store.state.user._id
    }

    axios.post('/api/metric/set', metricData)
      .then(() => console.log('msc'))
      .catch(error => console.error('mer', error))
  } 
  return true
});

// ...
export default router