<template>
  <a-modal  :visible="visible" :closable="false" :footer="null" :width="320"  >
    <div class="h">
      <p>{{ socialData }}</p> 
      <img src="../assets/img/line.svg">
    </div>
    <div class="block" >
      <icon :name=socialData class="img"  />
        <!-- <img class="img" :src="src"> -->
        <div class="inputs">
          <div>
           <label for="user" class="text">Title</label>
            <input id="user" v-model="username" class="inp1" >
          </div>
          <div>
            <!-- <label for="title" class="text1">Link</label> -->
          <input id="title" v-model="links" class="inp" :placeholder= place  >
          </div>
        </div>
        <div class="buttons">
          <button class="cancel" @click="cancel">Cancel</button>
          <button class="save" @click="save">Save</button>
        </div>
       
    </div>
  </a-modal>
</template>

<script>

import axios from '/src/axios/axios';
import icon from '../components/SetIconVue.vue'
export default {
  props: ['show_dialog','socialData','tags','arr'],
  components:{
    icon
  },
 data(){
   return {
    src:'',
    showDialog: false,
    visible: false, 
    title:'',
    links:'',
    username:'',
    place:'',
    socialLinks: {
      X: 'x.com/',
      Instagram:'instagram.com/',
      Linkedln:'linkedin.com/',
      Skype:'join.skype.com/invite/',
      Snapchat:'snapchat.com/',
      Facebook:'facebook.com/',
      Signal:'signal.me/',
      YouTube:'youtube.com/',
      Pinterest:'pinterest.com/',
      WhatsApp:'wa.me/',
      Messenger: 'm.me/',
      Telegram:'t.me/',
      Dribble: 'dribbble.com/',
      GooglePay:'pay.google.com/',
      Paypal:'paypal.com/',
      AmazonPay:'pay.amazon.com/',
      Behance:'behance.net/',
      Binance: 's.binance.com/'
    }
   }
 },

 mounted() {
    this.username = this.socialData
    this.tagsdata = this.tags
  },
  updated(){
    this.username = this.socialData
  },

  watch: {
    show_dialog:function(val) {
      this.visible = val;
    this.showDialog = val;
    },
    socialData(newSocialData) {
      // При изменении socialData обновляем ссылку links
      this.links = this.socialLinks[newSocialData] || '';
    }
  },
  methods:{
    cancel(){
      this.visible = false;
      this.$emit('close')
    },
    save() {
      axios.post('/api/tag/save/', { 
        url: this.tags,
        status: this.arr.status,
        uid: this.$store.state.user._id, 
        links: { 
          [this.socialData]: { 
            name: this.username, 
            link: this.links 
          }
        }
      })
      this.visible = false
      this.$emit('close')
      this.$emit('save-complete')
    }
  }
}
</script>

<style scoped>
.wrapper{
  border-radius: 10px;
}
.h{
  margin-bottom: 15px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  position: relative;
  align-items: center;
  text-align: center;
  color: #222222;
  padding-bottom: 15px;
}

.block{
  display: block;
}

.inputs{
  margin-top:29px;
}
.inp{
    position: relative;
    margin-bottom: 10px;
    width: 250px;
    height: 48px;
    background: #F4F4F4;
    border:none;
    border-radius: 5.46512px;
    margin-left:8px;
    margin-right:8px;
    padding-left: 16px;
    outline: none;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    direction: ltr;
    text-align: right;
    padding-right:20px;
    color: #222222;
}

.inp1{
    position: relative;
    margin-bottom: 10px;
  width: 250px;
  height: 48px;
  background: #F4F4F4;
  border:none;
  border-radius: 5.46512px;
  margin-left:8px;
  margin-right:8px;
 padding-right: 20px;
  outline: none;

  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;
/* identical to box height */

text-align: right;

/* 222222 */

color: #222222;
}
.text{
  position: relative;
  z-index: 999;
  top:35px;
  left:20px;

  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;


color: #8A8A8A;
}

.text1{
  position: relative;
  z-index: 999;
  top:35px;
  left:20px;

  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;


color: #8A8A8A;
}
.img{
  margin-left: 137px;
    display: block;
}

.modalWrapClass{
    border-radius: 40px;
    background-color: #f0f0f0;
}

.cancel{
  
  width: 125px;
  height: 40px;
  border:none;
  margin-top:80px;
  
background: none;
border: 1px solid #222222;
border-radius: 5px;
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
text-align: center;

/* 222222 */

color: #222222;
}

.save{
  width: 125px;
  height: 40px;
  border:none;
  margin-top:80px;
  margin-left:21px;
  background: #50DB78;
border-radius: 5px;

border: 1px solid #222222;
border-radius: 5px;
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
text-align: center;

/* 222222 */

color: #222222;
}

.img{
  transform: scale(1.9);
}

</style>







