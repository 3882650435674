<script>
import axios from '../../axios/axios'
export default {
    mounted(){
        try{
            axios.post('/api/product/list/')
            .then(response => {
                this.products = response.data
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.loading = false; // Set loading to false after the API call is done
            });
        }catch(e){
            console.error(e)
        }
    },
    data(){
        return {
            open: false,
            products: []
        }
    },
    methods: {
        showModal: () => {
            this.open = true
        },
        handleOk: e => {
            console.log(e)
            this.open = false
        }
    }
}
</script>
<template>
    <div class="wrapper">
        <h1>Товары</h1>
        <button>Добавить товар</button>
        <a-button type="primary" @click="showModal">Open Modal</a-button>
        <a-modal @open="open" title="Basic Modal" @ok="handleOk">
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        </a-modal>
        <div class="list">
            <div v-for="item in this.products" :key="item._id" class="product" >
                <img class="disigne" src="../../assets/img/disigne.svg" alt="product">
                <label class="discription">{{ item.name }}</label>
                <label class="price">${{item.price}}</label>
            </div>
        </div>
    </div>
</template>
<style scoped>
button{
    margin: 20px;
}
</style>