<template>
    <div></div>
</template>

<script>
import axios from '/src/axios/axios'

export default{
    mounted(){
        const profileId = this.$route.query
        let data = {
            code: profileId.code, 
            reason: profileId.reason
        }
        if(profileId.new_email) data.new_email = profileId.new_email
        console.log(profileId)
        axios.post('/api/user/activate/', data)
        .then(response => {
            console.log(response)
            if(data.reason == 'changeemail'){
                this.$store.commit('removeUser')
                localStorage.removeItem('myData')
            }
            this.$router.push('/login');
            
        })
        .catch(err => {
            console.error(err)
        })
    }
}
</script>