<template>
  <div>
    <div class="main">
      <div class="h">
        <img class="mas" src="../assets/MAS.svg">
      </div>
      <div class="block">
        <p class="text">Enter a new password.</p>
        <div class="inputblock"></div>
        <div class="inp1">
          <a-alert
            v-if="serverResponse === false"
            description="The password must be at least 8 characters long."
            type="error"
            show-icon
          />
          <a-alert
            v-if="serverResponse === true"
            description="Password has been successfully changed"
            type="success"
            show-icon
          />
          <input id="email" class="inp" type="password" v-model="password" placeholder="Password">
      
        </div>
      </div>
      <div class="button">
        <button @click="save" class="btn">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '/src/axios/axios';
 export default{
    data(){
        return{
            email:'',
            code:'',
            password:'',
            reason:'',
            serverResponse:''
        }
    },
    mounted(){
        const profileId = this.$route.query;
        this.reason = profileId.reason;
        this.code = profileId.code;
        console.log(this.code)
    },
    methods:{
        save(){
        axios.post('/api/user/newPass',{ password: this.password, reset: this.code, reason: 'newPass' })
        .then(response => {
          if(response.data === false){
            this.serverResponse = false
          } else {
            this.serverResponse = true
            setTimeout(() => {
              this.$router.push('/login')
            }, 2000)
          }
        })
      }
    }
  }
</script>

<style scoped>
.main{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}
.h{
  position: relative;
  margin-top: 50px;
  align-items: center;
  
}

.block{
  position: relative;
align-items: center;
margin-top:139px;
}


.text{
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */

text-align: center;

/* 222222 */

color: #222222;
padding-bottom: 20px;
}

.inp1{
    display: flex;
  display: inline-block;
  flex-direction: column;
    
}
.inp{
margin-top:20px;
  width: 325px;
  height:48px ;
  background: #F4F4F4;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  outline: none;
  display: flex;
  
}

.button{
  margin-top:60px;
}

.btn{

width: 173.4px;
height: 45.63px;
background: #363636;
border-radius: 8px;
border:none;
color:#FFFFFF;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
text-transform: capitalize;
box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
}



.btnText{
  position: absolute;
left: 39.2%;
right: 39.2%;
top: 78.6%;
bottom: 16.07%;

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
text-transform: capitalize;

color: #FFFFFF;
}
</style>