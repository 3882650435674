<template>
  <div class="mainPage">
    <div v-if="loading" class="preloader"></div>
    <div class="block" v-else-if="this.per === true && arr.status === 'active'  && arr.uid !=null">
      <div class="loading"></div>
      <div class="photo">
        <img v-if="arr.photo" class="mainphoto" :src="arr.photo" alt="avatar">
        <img v-else class="mainphoto" src="../assets/img/default.svg" alt="avatar">
      </div>
      <div class="description">
        <div class="name">
          <p class="profileName">{{ arr.name }}</p>
        </div>
        <div class="about">
          <p class="aboutProfile">{{ arr.description }}</p>
        </div>
       
      </div>
      <div class="buttons">
        <!-- <button @click="create" class="create">Create Your Profile</button> -->
        <button @click="generateAndDownloadVCard" class="save">Save contact</button>
        <button v-if="arr.number" @click="callPhoneNumber(arr.number)" class="call">Call</button>
      </div>
      <div class="block1">
        <div v-for="(value, key) in arr.links " :key="key" class="social" @click="myLink(arr.links[key], key)" > 
          <a :href=getLink(value.link) target="_blank">
           
            <icon :name="key" class="pic"/>
            <div class="textfor">
                <p class="labelSocial">{{arr.links[key].name}}</p>
            </div>
          </a>
   
        </div>
      </div>
   
    </div>
    <div v-else-if="!arr">
      <p class="page">
          <span class="first-half">4</span>
          <span class="second-half">04</span>
        </p>
        
        <p class="page1">
          <span class="third-half">Page n</span>
          <span class="fourth-half">ot found</span>
        </p>
      
    </div>
    <div v-else-if="!arr.uid && arr.status === 'unactivated'">
      <icon name="mas" class="masimg"/>
      <div class="textQr">
        <span class="textForQr">Scan the QR code to add a tag</span>
      </div>
      <div class="qr">
        <qrcode :value="qrCodeText" />
      </div>
      
    </div>
    <div v-else>
      <p class="page">
          <span class="first-half">4</span>
          <span class="second-half">04</span>
        </p>
        
        <p class="page1">
          <span class="third-half">Page n</span>
          <span class="fourth-half">ot found</span>
        </p>
    </div>
  </div>
</template>

<script>
import axios from '/src/axios/axios'
import icon from '../components/SetIconVue.vue'
import Qrcode from 'vue-qrcode'

export default {
  components: {
  icon,
  Qrcode
 },
  data(){
    return{
      arr: [],
      per: '',
      loading: true,
      qrCodeText: '123',
      isLoggedIn: ''
    }
  },
  mounted() {
    this.profileId = this.$route.params.id
    this.qrCodeText = 'https://mastag.online/' + this.profileId
    setTimeout(() => {
      console.log(888, this.arr)
      if(this.arr && this.arr.status != 'active' && !this.arr.uid) {
        const cookies = this.$cookies.get('name_start')
        console.log(444, cookies)
        if(cookies){
          axios.post('api/tag/save', {
            url: this.profileId, 
            status: 'active', 
            uid: cookies, 
            name: 'Tag', 
            description: 'My New Tag'
          })
          .then(() => {
            this.$router.push('/list')
          })
        }
      }
    }, 2000)
    this.check(this.profileId)
  },
  methods:{
    create(){
      window.location.href = "/login"
    },

    redirect(response){
      if(response.data.direct){
        console.log(123,window.location.href)
        for(let link in response.data.links){
          if(response.data.direct == response.data.links[link].name) {
            window.location.href = this.getLink(response.data.links[link].link)
          }
        }
      }
    },

    check(profileId){
      try{
        axios.post('/api/tag/checkurl/', {profileId})
        .then(response => {
          
          if(response.data){
            this.per = true
            this.redirect(response)
          } else{
            this.per = false
          }
          if(response.data.error)this.$router.push('/404')
          this.arr = response.data
          
          console.log(123, response.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false; // Set loading to false after the API call is done
        });
      }catch(e){
        console.error(e)
      }
    },

    myLink(value,key){
      this.showDialog1 = true
      this.objectmodal = value; //для передачи значеия в модалку 
      this.keymodal = key; //для передачи значеия в модалку 
      console.log(555555,value,key)
    },

    callPhoneNumber(phoneNumber) {
      if (/(android|iphone|ipad)/i.test(navigator.userAgent)) {
        const telLink = `tel:${phoneNumber}`
        window.location.href = telLink
      } else {
        console.log('Your device does not support calling from the browser.')
      }
    },

    generateAndDownloadVCard() {
      const contactVCard = this.generateVCard()

      const blob = new Blob([contactVCard], { type: 'text/vcard' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = this.arr.name + '.vcf'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    convertUrlToBase64(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";

        xhr.onload = function() {
          if (xhr.status === 200) {
            const arrayBuffer = xhr.response;
            const base64 = btoa(
              String.fromCharCode(...new Uint8Array(arrayBuffer))
            );
            resolve(`data:image/jpeg;base64,${base64}`);
          } else {
            reject(new Error("Error fetching image"));
          }
        };

        xhr.onerror = function() {
          reject(new Error("Error fetching image"));
        };

        xhr.send();
      });
    },

    generateVCard() {
      let vCardContent = 'BEGIN:VCARD\nVERSION:3.0\n'
      if(this.arr.name) vCardContent += 'N;CHARSET=utf-8:' + this.arr.name + '\n'
      if(this.arr.description) vCardContent += 'NOTE;CHARSET=utf-8:' + this.arr.description + '\n'
      if(this.arr.number.length) vCardContent += 'TEL;TYPE=CELL:' + this.arr.number + '\n'
      if(this.arr.links.Email && this.arr.links.Email.link) vCardContent += 'EMAIL;TYPE=EMAIL:' + this.arr.links.Email.link + '\n'
      // if(this.arr.photo) vCardContent += 'PHOTO;VALUE=URL;TYPE=JPEG:' + this.arr.photo + '\n'
      console.log(this.arr.photo)
      if (this.arr.photo_encode) {
        vCardContent += 'PHOTO;ENCODING=b;TYPE=JPEG:' + this.arr.photo_encode + '\n'
      }
      for(let element in this.arr.links) {
        if(element != 'Email') vCardContent += 'URL;TYPE=URL:' + this.getLink(this.arr.links[element].link) + '\n'
      }
      vCardContent += 'URL;TYPE=URL:https://mastag.online/tag/' + this.arr.url + '\n' +
      'UID:urn:uuid:' + this.arr.url + '\n' +
      'END:VCARD'
      return vCardContent
    },
    
    comboLink(link, key) {
      //для объединения ссылок 
      if (link && this.socialLinks[key]) return this.socialLinks[key] + link
    },

    getLink(link) {
      let new_link
      if (link.startsWith('http://') || link.startsWith('https://')) new_link = link
      else new_link = 'https://' + link
      
      let check_email = this.ifContainsEmail(link)
      if(check_email) new_link = 'mailto:' + link
      return new_link
    },

    ifContainsEmail(string){
      const emailRegExp = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
      return emailRegExp.test(string)
    }
  }
}
</script>

<style scoped>
.page{
  margin-top:350px;
}
.first-half{
  margin-top:350px;
  color:#72D48E;
  font-family: 'Gemunu Libre';
  font-style: swap;
  font-weight: 700;
  font-size: 90px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  padding-bottom: 15px;
}

.second-half{
  margin-top:350px;
  color:#363636;
  font-family: 'Gemunu Libre';
  font-style: swap;
  font-weight: 700;
  font-size: 90px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  padding-bottom: 15px;
}

.page1{
  margin-top:25px;
}

.fourth-half{
  font-family: 'Gemunu Libre';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  color:#72D48E;
  padding-bottom: 15px;
}

.third-half{
  font-family: 'Gemunu Libre';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  padding-bottom: 15px;
}
.block{
  /* padding-bottom: 290px; */
  padding-top: 75px;
  position: relative;
  /* overflow-y: scroll; */
  /* background: url('../assets/img/background2.jpg'); */
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.description{
  position: relative;
  margin-top:15px;
}

.profileName{
  display: inline-block;
  margin-bottom: 2px;
  font-family: 'Outfit';
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000;
}

.aboutProfile{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000;
}

.mainphoto{
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  align-items: center;
}

.photo{
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  background: #fff;
  border-radius: 50%;
}

.buttons{
  margin-top:21.16px;
}

.create{
  position: relative;
  border-radius: 3.33864px;
  border:none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10.52px;
  line-height: 12px;
  text-align: center;
  color: #222222;
}

.call{
  position: relative;
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 3.33864px;
  border:none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10.52px;
  line-height: 12px;
  text-align: center;
  color: #222222;
}
.save{
  position: relative;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 3.33864px;
  border:none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10.52px;
  line-height: 12px;
  text-align: center;
  color: #222222;
}

.block1{
  margin-top:26.24px;
  justify-content: center;
  display:flex;
  flex-wrap: wrap;
  background: red;
  padding-top:20.37px;
  background: linear-gradient(126.65deg, rgba(255, 255, 255, 0.18) 28.67%, rgba(255, 255, 255, 0) 105.36%);
  opacity: 0.9;
  border: 0.655039px solid rgba(232, 232, 232, 0.2);
  backdrop-filter: blur(16.376px);
  border-radius: 9.82559px;
}

.social{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  background: #F4F4F4;
  border-radius: 7px;
  padding-bottom: 20px;
  padding-right:15px;
  padding-top: 10px;
  padding-left: 15px;
  margin-right:7.5px;
  margin-left:7.5px;
  overflow: hidden;
  margin-bottom:13px;
}
.social a{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pic{
  max-width: 60px;
  max-height: 60px;
}

.textfor{
  margin-top: 10px;
}
.labelSocial{
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 12.95px;
  line-height: 16px;
  text-align: center;
  color: #222222;
  overflow: hidden;
}


.preloader {
    border: 4px solid #363636;
    border-top: 4px solid #72D48E;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 120px auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .qr{
    margin-top:80px;
  }

  .masimg{
  display: block;
  margin-top: 250px;
}
.textQr{
  margin-top: 60px;
  font-family: 'Outfit';
  font-style: bold;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.mainPage{
  overflow: hidden;
}
</style>