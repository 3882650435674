<template>
  <div class="nfc">
    <div class="h">
      <p>Orders</p>
    </div>
    <div class="block">
      <div class="preview">
        <img class="card" src="../assets/img/orderpic.svg">
        
        <div class="text">
          <p class="price">$21.98</p>
          <p class="discription">NFC tag disign x2</p>
          <p class="related">related for “My teg”</p>
        </div>
        <div class="buttons">
          <button class="collect" @click="collect">collect</button>
          <p class="date">28.10.2022</p>
        </div>
      </div>
      <div class="preview">
        <img class="card" src="../assets/img/orderpic.svg">
      
        <div class="text">
          <p class="price">$21.98</p>
          <p class="discription">NFC tag disign x2</p>
          <p class="related">related for “My teg”</p>
        </div>
        <div class="buttons">
          <button class="onway" @click="onway">on way</button>
          <p class="date">28.10.2022</p>
        </div>
      </div>
      <div class="preview">
        <img class="card" src="../assets/img/orderpic.svg">
      
        <div class="text">
          <p class="price">$21.98</p>
          <p class="discription">NFC tag disign x2</p>
          <p class="related">related for “My teg”</p>
        </div>
        <div class="buttons">
          <button class="delivered" @click="delivered">delivered</button>
          <p class="date">28.10.2022</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    if(!this.$store.state.user){
      this.$router.push('/login')
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Переход на предыдущую страницу
    }
  }
}
</script>


<style scoped>
body {
  margin: 0;
  padding: 0;
}
.nfc{
position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.back{
  position: absolute;
  left:30px;
  top:2px;
}

.h{
  margin-top: 70px;
  margin-bottom: 15px;
font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
position: relative;
align-items: center;
text-align: center;
color: #222222;
padding-bottom: 15px;
}
.h:after{
  background: url('../assets/List/Vector4.svg');
  
    content: '';
    width: 325px;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 162.5px);
}

.block{
  margin-top:15px;
}
.preview{
  flex:1;
  display: flex;
  flex-direction: row;
  margin-left: 21.39px;
  margin-bottom:20px;
}

.card{
  background: #EFEFEF;
  border-radius: 10px;
}

.text{
  margin-left:13px;
}
.price{
  margin-right:50px;
  font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;

color: #000000;
}

.discription{
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
/* identical to box height */

text-align: center;

/* 222222 */

color: #222222;
}

.related{
  margin-top:10px;
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 15px;

color: #8D8D8D;
}

.collect{
  margin-left:40px;
  width: 69px;
height: 34px;
border:none;
background: #EFEFEF;
border-radius: 4px;

font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #323232;
}

.date{
  position: absolute;
  margin-top:21px;
  margin-left:43px;
  font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;

color: #8B8B8B;
}

.onway{
  margin-left:40px;
  width: 69px;
height: 34px;
border:none;
background: #EFEFEF;
border-radius: 4px;

font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #1B73F8;
}

.delivered{
  margin-left:40px;
  width: 80px;
height: 34px;
border:none;
background: #EFEFEF;
border-radius: 4px;

font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #72D48E;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
</style>