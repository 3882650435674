<template>
    <div class="main">
        <!-- <icon name="mas" class="mas"/> -->
        <div class="text">
            <span class="work">If you are having any difficulties with our product or service, please contact our support team at</span>
            <a href="mailto:support@mastag.online" class="support1">
                <span class="email">support</span>
                <span class="email1">@mastag.online</span>
            </a>
        </div>
        <div class="text">
            <span class="work">Follow us in social networks</span>
            <div class="socials">
                <div class="item">
                    <a href="https://x.com/mastagonline" target="_blank">
                        <icon name="X" />
                    </a>
                </div>
                <div class="item">
                    <a href="https://instagram.com/mastag.online" target="_blank">
                        <icon name="Instagram" />
                    </a>
                </div>
                <div class="item">
                    <a href="https://t.me/mastag_online" target="_blank">
                        <icon name="Telegram" />
                    </a>
                </div>
                <div class="item">
                    <a href="https://www.facebook.com/mastagproject" target="_blank">
                        <icon name="Facebook" />
                    </a>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import icon from '../components/SetIconVue.vue'

export default{
    components:{
        icon
    }
}
</script>

<style scoped>
.main{
    position: relative;
    overflow-y: scroll;
    background: #FFFFFF;
}

.mas{
    display: inline-block;
    position: relative;
    margin-top: 50px;
}

.text{
    margin-top:50px;
}

.work{
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    color: #222222;
}

.support1{
    display: block;
}

.email{
    color:#72D48E;
    font-family: 'Outfit';
    font-size: 21px;
}
.email1{
    color:#363636;
    font-family: 'Outfit';
    font-size: 21px;
}
.socials{
    display: flex;
    justify-content: center;
    align-items: center;
}
.socials .item{
    margin-left: 15px;
    margin-right: 15px;
}
.icon{
    max-width: 50px;
}
</style>