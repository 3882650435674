<template>
  <div class="login">
    <div class="h">
      <img class="mas" src="../assets/MAS.svg">
    </div>
    <div class="block">
      <div class="p">
        <p class="text">Become a part of our community.</p>
      </div>
      <div class="input">
        <a-alert
          v-if="serverResponse === false"
          description="User with this email already exists."
          type="error"
          show-icon
        />
        <a-alert
          v-if="serverResponse === 'wrong'"
          description="The password must be at least 8 characters long."
          type="error"
          show-icon
        />
        <input class="inp1" type="email" placeholder="Email" v-model="email">
        <!-- <input class="inp1" type="username" placeholder="Username" v-model="username"> -->
        <input class="inp1" type="password" placeholder="Password" v-model="password">
        <a-checkbox class="checkbox" v-model:checked="checked">I agree with the privacy policy.</a-checkbox>
      </div>
      <div class="button">
        <button :disabled="!checked && !inputer" :class="{disabled: !checked && !inputer}" @click="press" class="btn">Sign Up</button>
      </div>
      <a class="login" href="/login">Login</a>
      <div class="dots">
        <img class="slider1" src="../assets/Login3/Rectangle272.svg">
        <img class="slider2" src="../assets/Login3/Rectangle273.svg">
        <img class="slider3" src="../assets/Login3/Rectangle271.svg">
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from '/src/axios/axios';
import { useStore } from 'vuex';
// import errorant from '../components/ErrorAnt.vue';
export default {
  setup() {
    const store = useStore();

    console.log(8899,store); // Вывод store в консоль
  },
 
  data(){
    return {
      email:'',
      password:'',
      // username:'',
      serverResponse:'',
      checked: false,
      inputer: false
    }
  },
  methods: {
    
    async press() {
      try{
        await axios.post('/api/user/signup/', { 
          email: this.email, 
          // username: this.username, 
          password: this.password, 
          reason: 'registration', 
          data:{
            email: this.email,
            // name: this.username
          } 
        })
        
        .then(response => {
          if(response.data === false){
            this.serverResponse = false
          } else if(response.data === 'wrong'){
            this.serverResponse = 'wrong'
          }
          
          else {
            console.log(1123123, response.data)
            this.$router.push('/login')
          }
            // axios.post('/api/user/mail/', {reason: 'registration' ,data:{email:this.email,name:this.username}})
            
            // console.log(1,response.data);
            
            // Дополнительные действия после успешной отправки данных
          })
          
          .catch(error => {
            // Обработка ошибок при отправке данных
            console.error(2, error)
          })
          
        // this.$router.push('/LoginSecond'); // переход на вторую страницу
      }
      catch(e){
        console.error(e)
      }
    }
  },
  watch: {
    inputer() {
      console.log(44)
      if(this.email.length > 5 && this.password.length > 7){
        this.inputer = true
      }
    }
  }
}
</script>


<style scoped>

.login{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.h{
  position: relative;
  margin-top: 50px;
  align-items: center;
}

.block{
  position: relative;
  margin-top: 97px;
}

.input{
  position: relative;
  margin-top: 1px;
  max-width: 325px;
  display: inline-block;
  padding-right: 8px;
}
.checkbox{
  margin-top: 15px;
}

.inp1{
  position: relative;
  width: 325px;
  height: 48px;
  margin-top:18px;
  background: #F4F4F4;
  border:none;
  border-radius: 5.46512px;
  padding-left: 10px;
  outline: none;
}

.inp2{
  position: relative;
  width: 325px;
  height: 48px;
  margin-top:28px;
  background: #F4F4F4;
  border:none;
  border-radius: 5.46512px;
  padding-left: 10px;
  margin-top: 14px;
  outline: none;
}

.text{
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #222222;
}

.button{
  margin:39px;
}

.btn{
  width: 173.4px;
  height: 52.15px;
  background: #72d48e;
  border-radius: 8px;
  border:none;
  color:#FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: capitalize;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
}
.disabled{
  background: #b1b1b1;
}

.dots{
  position: relative;
  padding: 15px;
}

.slider2{
  margin-left: 8px;
}

.slider3{
  margin-left: 8px;
}

</style>