<template>
  <div>
    <div class="login">
      <div class="h">
        <img class="mas" src="../assets/MAS.svg">
      </div>
      <div class="block">
        <p class="text">User Authentication</p>
        <div class="inputblock">
          <a-alert
            v-if="serverResponse === false"
            description="Invalid email or password."
            type="error"
            show-icon
          />
          <div class="buttonres">
            <button class="but" v-if="serverResponse === false"  @click="redirect">Reset password</button>
          </div>
          <div class="inp1">
            <input id="email" class="inp" v-model="email" placeholder="Email">
          </div>
          <div class="inp2">
            <input id="pass" type="password" class="inp" v-model="password"  placeholder="Password">
          </div>
          <div class="toReg" @click="toreg">
            <p class="registration">or Sign Up</p>
          </div>
        </div>
      </div>
    
      <div class="button">
        <button @click="press" class="btn">Continue</button>
      </div>
      <div class="sliders">
        <img class="first" src="../assets/Rectangle271.png">
        <img class="second" src="../assets/Rectangle272.png">
        <img class="third" src="../assets/Rectangle273.png">
      </div>
    </div>
  </div> 
</template>

<script>
import axios from '/src/axios/axios'
// import { useStore } from 'vuex'

// import {http} from './axios/HTTP'
export default {
  setup() {
    // const store = useStore()
    // console.log(store)
  },
  data() {
    return {
      email: '',
      password: '',
      serverResponse: ''
    }
  },
  methods: {
    press() {
      axios.post('/api/user/login/', { email: this.email, password: this.password}) //отправляю дату на бэк
        .then(response => {
          if(response.data === false ){
            this.serverResponse = false
          } else {
            this.$router.push('/list')
            this.$store.commit('increment', response.data) 
            
            const myData = this.$store.state.user
            console.log(11, myData)
            localStorage.setItem('myData', JSON.stringify(myData))
          }
          
          // Обработка успешного ответа от сервера
          
          console.log(1, response)
          // Дополнительные действия после успешной отправки данных
        })
        .catch(error => {
          // Обработка ошибок при отправке данных
          console.error(2, error)
        })
    },
    toreg() {
      this.$router.push('/signup')
    },
    redirect(){
      this.$router.push('/ResetpassOne')
    }
  },
  mounted(){
    let data = this.$store.state && this.$store.state.user;
    if(data){
      this.$router.push('/list')
    }
  }
}
</script>
<style scoped>
.login{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.h{
  position: relative;
  margin-top: 50px;
  align-items: center;
}

.block{
  position: relative;
  align-items: center;
  margin-top:139px;
}

.text{
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #222222;
}

.button{
  margin:48px;
}

.btn{
  width: 173.4px;
  height: 45.63px;
  background: #72d48e;
  border-radius: 8px;
  border:none;
  color:#FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: capitalize;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
}

.btnText{
  position: absolute;
  left: 39.2%;
  right: 39.2%;
  top: 78.6%;
  bottom: 16.07%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
}

.sliders{
  position: relative;
  align-items: center;
  margin-top: 50px;
  padding-right: 8px;
}

.first{
  margin-left:8px;
}
.second{
  margin-left:8px;
}

.third{
  margin-left:8px;
}

.inputblock{
  display:inline-block;
  position: relative;
  width: 325px;
  height: 48px;
  margin-top:18px;
}

.inp1{
  margin-bottom: 15px;
}

.inp{
  width: 325px;
  height:48px ;
  background: #F4F4F4;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  outline: none;
}

.registration{
  padding-top:10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #222222;
}

.toReg{
  position: relative;
  width: 70px;
  display:inline-block;
  top:15px;
}

.but{
  width:120px;
  height:20px;
  border: none;
  background: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #222222;
  padding-bottom: 15px;
}

.buttonres{
  margin-top:10px;
  padding-bottom: 10px;
}
</style>