<template>
  <div class="list__">
    <div class="preloader" v-if="loading"></div>
    <div v-else>
      <div class="list">
        
        <!-- <div class="search">
          <button class="search_btn">
            <img src="../assets/List/Vector.svg" alt="иконка поиска">
          </button>
          <input class="search_input" type="text" placeholder="Search...">
        </div> -->
        <div class="list" v-if="arr.length">
          <a class="add" @click="this.$router.push('/start')">Add MAS</a>
          <div v-for="tag in arr" :key="tag._id" class="profileList" @click="toTag(tag)"> 
            <img v-if="tag.photo && tag.photo.length" class="photo" :src="tag.photo">
            <img v-else src="../assets/img/default.svg" alt="photo">
            <div class="profileName">
        
              <div class="name_wrapper">
                <span :class="{'name': true, 'inactive': tag.status === 'inactive'}">{{ tag.name }}</span>
                <div class="vis" @click.stop="vis(tag)">
                  <div class="eyes">
                    <icon :name="tag.status"/>
                  </div>
                </div>
              </div>
              <span :class="{'description': true, 'inactive': tag.status === 'inactive'}">{{ tag.description }}</span>
            </div>
          </div>
          
        </div>
        <div v-else>
          <div class="lol"></div>
          <a class="add" @click="this.$router.push('/start')">Add MAS</a>
          <!-- <p>You don't have tags yet.</p>
          <p><a @click="this.$router.push('/shop')">Buy tag</a> or <a @click="this.$router.push('/start')">activate</a> if you have already</p> -->
        </div>
      </div>
      
    </div>
  </div>
</template>
  
<script>
import icon from '../components/SetIconVue.vue'
import axios from '/src/axios/axios'
import atob from 'atob'
  export default {
    
    data(){
      return{
        arr: [], // массив для даты меток
        loading: true ,
        photo:''
      }
    },
    mounted() {
      if(!this.$store.state.user){
        this.loading = false
        this.$router.push('/login')
      }

      if (this.$store.state.user && this.$store.state.user.photo) this.photo = atob(this.$store.state.user.photo)
       
      this.list()
    },
    components: {
      icon,
    },
    // computed: {
    //   username() {
    //     return this.$store.state.user.name;
    //   }
    // },
    
    methods: {
      toTag(tag) {
        this.$router.push({
          name: 'My tag',
          query: { tag: encodeURIComponent(tag.url) }
        })
      },

      async list() {
        await axios.post('/api/tag/list/', this.$store.state.user) // отправляю на бэк дату со стора 
        .then(response => {
          this.arr = response.data //для полученной даты о метках
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false // Set loading to false after the API call is done
        })
      },

      vis(tag){
        if (tag.status === 'active') tag.status = 'inactive'
        else tag.status = 'active'
        
        axios.post('/api/tag/change/', {url: tag.url, status: tag.status}) 
      }
    }
  }
</script>
<style scoped>
.yet{
  margin: 20px;
  display: flex;
  align-items: center;
}
.lol{
  margin-top: 200px;
}
.list{
  padding-bottom:130px;
}
.add{
  background: #72d48e;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
}
.name_wrapper{
  position: relative;
  display: flex;
  padding: 0 15px 0 0;
  margin-bottom: 5px;
  width: max-content;
}

.search_input{
  border: none;
  background: none;
  padding-left: 50px;
  padding-top: 20px;
  width: 80%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #8A8A8A;
}
.search_input:focus-visible{
  outline: none;
}
.search_btn{
  position: absolute;
  border: none;
  background: none;
  left: 10px;
  top: 15px;
}
.search{
  display: inline-block;
  width: 325px;
  height: 55px;
  background: #F4F4F4;
  border-radius: 10px;
}
.list{
  position: relative;
  background: #FFFFFF;
}


.line{
  position: absolute;
  width: 325px;
  height: 0px;
}

.profileList{
  margin-top:30px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.profileList img{
  max-width: 45px;
  max-height: 45px;
}
.eyes{
  margin-left:8px;
  margin-top: 2px;
}


.profileName{
  margin-left: 19px;
  display: flex;
  flex-direction: column;
}

.name{
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 15.8724px;
  line-height: 20px;
  align-items: center;
  color: #222222;
  display: inline-block;
  align-self: flex-start;
}

.description{
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 12.2706px;
  line-height: 15px;
  color: #222222;
  text-align: left;
}

.photo{
  border-radius: 50%;
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.search{
  position: relative;
}

.rectangle{
  position: absolute;
  width: 325px;
  height: 55px;
  top: 13px;
  background: #F4F4F4;
  border-radius: 10px;
}

.inactive {
  color: #808080;
}

.burger{
  display: inline-block;
  position: absolute;
  margin-left: 90px;
  margin-top: -45px;
}
.preloader {
  border: 4px solid #363636;
  border-top: 4px solid #72D48E;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 120px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>