<template>
  <a-modal :visible="visible" :closable="false" :footer="null" :width="320">
    <div class="h">
      <p>Tag edit</p>
      <img src="../assets/img/line.svg">
    </div>
    <div class="block">

      <div class="inputs">
        <div class="title">
          <label for="title" class="text">Name</label>
          <input id="title" class="inp" v-model="name">
        </div>
        <div class="note">
          <label for="note" class="text">Description</label>
          <textarea :rows="4" id="note" class="inpNote" v-model="description"/>
        </div>
        <div class="num">
          <label for="num" class="text">Phone</label>
          <input id="num" type="tel" class="inputNum" v-model="number">
        </div>
        
        <a-popover title="Short URL" trigger="hover">
          <template #content>
            <p>Create your uniqueness with a short link</p>
          </template>
          <div class="shortUrl">
            <label for="num" class="text">Short Url</label>
            <input id="num" class="inputNum" v-model="shortUrl">
          </div>
        </a-popover>
        
        <div class="direct">
          <label for="direct" class="text">Direct</label>
          <a-popover title="Direct" trigger="hover">
            <template #content>
              <p>Directly lead to a social network.</p>

            </template>
            <a-select id="direct" 
              class="select" 
              v-model:value="direct" 
              @change="handleChange" 
              @clear="handleClear"
              mode="single" 
              show-search 
              :allowClear="true" 
              :notFoundContent="customNoDataMessage()" 
              placeholder="Select direct link">
              <a-select-option v-for="option in arr.links" :key="option.name" :value="option.name">
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-popover>
          
        </div>
      </div>

      <div class="buttons">
        <button class="cancel" @click="cancel">Cancel</button>
        <button class="save" @click="save">Save</button>
      </div>

    </div>
  </a-modal>
</template>
  
<script>
import axios from '/src/axios/axios';
export default {
  props: ['show_dialog', 'socialData', 'message', 'arr', 'stringtag'],
  mounted() {
    this.initializeData();
  },
  updated() {
    this.initializeData();
  },
  data() {
    return {
      showDialog: false,
      visible: false,
      description: '',
      number: '',
      name: '',
      direct: ''
    }
  },
  watch: {
    show_dialog: function (val) {
      this.visible = val;
      this.showDialog = val;
    }
  },

  methods: {
    initializeData() {
      this.name = this.arr.name
      this.description = this.arr.description
      this.number = this.arr.number
      this.shortUrl = this.arr.short_url
      this.direct = this.arr.direct
    },

    handleChange(val) {
      // this.direct = ''
      if(!val)this.direct = ''
      else this.direct = val
      console.log(5, this.direct, val)
    },

    handleClear(val){
      console.log(6, this.direct, val)
    },

    cancel() {
      this.visible = false
      this.$emit('close')
    },

    customNoDataMessage() {
      return <span>Firstly add links to your tag</span>;
    },

    async save() {
      console.log(4, this)
      await axios.post('/api/tag/change/', { 
        url: this.stringtag, 
        direct: this.direct, 
        name: this.name, 
        description: this.description, 
        number: this.number, 
        short_url: this.shortUrl 
      })
      this.visible = false
      this.$emit('close')
      this.$emit('save-complete') //для обновления родительской страницы
    }
  }
}
</script>
  
<style scoped>
.wrapper {
  border-radius: 10px;
}

.h {

  margin-bottom: 15px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  position: relative;
  align-items: center;
  text-align: center;
  color: #222222;
  padding-bottom: 15px;
}

.block {

  display: flex;
  display: block;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin-top: 29px;
  justify-content: space-between;
}

.text {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #8A8A8A;
  margin-top: 14px;
  margin-left: 10px;
}

.title {
  display: flex;
  width: 260px;
  height: 48px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 17px;
}

.inp {
  position: relative;
  left: 40px;
  width: 150px;
  border: none;
  background: #F4F4F4;
  border-radius: 5.46512px;
  outline: none;
  z-index: 99;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  outline: none;
  text-align: right;

  /* 222222 */

  color: #222222;
}

.note {
  justify-content: space-between;
  display: flex;
  width: 260px;
  height: 140px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 17px;
}

.inpNote {
  position: absolute;
  top: 220px;
  left: 40px;
  height: 80px;
  width: 190px;
  border: none;
  background: #F4F4F4;
  outline: none;
  z-index: 99;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  outline: none;
  /* 222222 */

  color: #222222;
}

.num {
  display: flex;
  width: 261px;
  height: 48px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 17px;
}

.shortUrl {
  display: flex;
  width: 261px;
  height: 48px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 17px;
}

.inputNum {
  position: absolute;
  left: 110px;
  width: 170px;
  height: 45px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
  outline: none;
  z-index: 99;

  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  /* 222222 */

  color: #222222;
}







.img {
  margin-left: 75px;
  display: block;
}

.modalWrapClass {
  border-radius: 40px;
  background-color: #f0f0f0;
}

.cancel {

  width: 125px;
  height: 40px;
  border: none;
  margin-top: 80px;

  background: none;
  border: 1px solid #222222;
  border-radius: 5px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  /* 222222 */

  color: #222222;
}

.save {
  width: 125px;
  height: 40px;
  border: none;
  margin-top: 80px;
  margin-left: 21px;
  background: #50DB78;
  border-radius: 5px;

  border: 1px solid #222222;
  border-radius: 5px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  /* 222222 */

  color: #222222;
}
.direct{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 8px;
}
label[for="direct"]{
  margin-bottom: 5px;
}
</style>













<!-- .inp{
  position: relative;
  margin-bottom: 10px;
width: 250px;
height: 48px;
background: #F4F4F4;
border:none;
border-radius: 5.46512px;
margin-left:8px;
margin-right:8px;

}

.inp1{
position: relative;
  margin-bottom: 10px;
width: 250px;
height: 133px;
background: #F4F4F4;
border:none;
border-radius: 5.46512px;
margin-left:8px;
margin-right:8px;

}
.inp1::placeholder{
padding-left: 10px;
font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;
color: #8A8A8A;
position: absolute;
top: 15px;
transform: translateY(-50%);
}
.inp::placeholder{
  padding-left:10px;
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;
/* identical to box height */


/* 8A8A8A */

color: #8A8A8A;
} -->
