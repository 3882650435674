<template>
  <div class="nfc">
    <div class="h">
      <img class="back" src="../assets/Profile/Back.svg" @click="goBack">
      <p>Order</p>
    </div>
    <div class="block">
      <div class="preview">
        <img class="card" src="../assets/img/disignefororder.svg">
        <p class="price" data-price="99">$10.<span class="small-decimal"></span></p>
        <div class="buttons">
          <p class="Quantity">Quantity</p>
          <button class="minus">-</button>
          <button class="plus">+</button>
        </div>
      </div>
    </div>
    <div class="block2">
      <div class="first">
        <label for="first" class="text">Select</label>
      <input id="first" class="forselect">
      </div>
      <div class="second">
        <label for="second" class="text">Country</label>
      <input id="second" class="forselect">
    </div>
    <div class="third">
      <label for="third" class="text">City</label>
      <input id="third" class="forselect">
    </div>
      <div class="input-wrapper">
        <label for="fourth" class="text">Adress</label>
       <textarea rows="2" cols="2" maxlength="70" id="fourth" class="forAdress"></textarea>
    </div>
    </div>
    <div class="block3">
      <button class="prev" @click="prev">Prev</button>
      <button class="buy" @click="prev">Pay</button>
    </div>
  </div>
</template>

<script>

  export default {
   
    mounted(){
    if(!this.$store.state.user){
        this.$router.push('/login')
      }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Переход на предыдущую страницу
    },
    minus(){
      alert('ok');
    }
    }
  }
  

    
</script>

<style scoped>

body {
  margin: 0;
  padding: 0;
}

.nfc{
position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.back{
  position: absolute;
  left:30px;
  top:2px;
}

.h{
  margin-top: 70px;
  margin-bottom: 15px;
font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
position: relative;
align-items: center;
text-align: center;
color: #222222;
padding-bottom: 15px;
}
.h:after{
  background: url('../assets/List/Vector4.svg');
  
    content: '';
    width: 325px;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 162.5px);
}

.block{
  margin-top:39px;
  display:block;
  
}

.preview{
  margin-left: 10px;
  display: block;
  flex:1;
  display: flex;
 
}

.card{
  margin-left:15px;
  background: #EFEFEF;
  border-radius: 10px;
}

.price{
  flex: 1;
  margin-left:120px;
  font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 33.5938px;
line-height: 42px;

color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.price::after {
  content: attr(data-price);
  font-size: 12px;

  font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 42px;

color: #000000;

}

.block2{
  position: relative;
  margin-top: 35px;
  display:inline-block;
}

.first{
  display: flex;
  position: relative;
  margin-bottom: 20px;
  width: 325px;
  height: 45px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;

  
}

.second{
  display: flex;
  position: relative;
  margin-bottom: 20px;
  width: 325px;
  height: 45px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
}

.third{
  display: flex;
  position: relative;
  margin-bottom: 20px;
  width: 325px;
  height: 45px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
}

.forselect{
  position: absolute;
  margin-left: 73px;
  width: 190px;
  height: 45px;
  background: #F4F4F4;
  border-radius: 5.46512px;
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;
text-align: right;
margin-left: 135px;
color: #222222;
outline: none;
border: none;
padding-right: 30px;
}

.text{
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;
color: #8A8A8A;
margin-top: 14px;
margin-left: 10px;
}
.input-wrapper {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  width: 325px;
  height: 106px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
}

.forAdress {
  position: absolute;
  margin-left: 10px;
  top:40px;
  outline: none;
  border: none;
  width: 300px;
  height: 38px;
  background: #F4F4F4;
  border-radius: 5.46512px;
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 19px;

color: #222222;
overflow: hidden;
}


.input-label {
  position: absolute;
  display: inline-block;
  top: 12px; 
  left: 37px; 
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #8A8A8A;
}

.block3{
  margin-top:33px;
  display: flex;
 display: block;
}

.prev{
  margin-left:1px;
  width: 92px;
  height: 34px;
  border: 1px solid #72D48E;
border-radius: 4px;
background: none;

font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #262626;

}

.buy{
padding-left:23px;
padding-right: 23px;
width: 92px;
height: 34px;
margin-left: 130px;
background: #72D58E;
border-radius: 4px;
border:none;

font-family: 'Outfit';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #FFFFFF;
}

.buttons{
  position: relative;
  display: flex;
  right: 80px;
  margin-top: 60px;
  right: 110px;
}

.minus{
  margin-right: 42px;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 10px;
}

.plus{
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 10px;
}

.Quantity{
  position: absolute;
  right: 120px;
  top:5px;

  font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 19px;
/* identical to box height */


color: #000000;
}



</style>