<template>
    <div class="main">
        <!-- <icon name="mas" class="mas"/> -->
        <div class="text">
            <!-- <span class="work">This page coming soon</span> -->
            <p>MAS is your gateway into the realm of digital business cards and internet services. We are at the forefront of innovation in the era of digital communication, offering modern solutions for creating and managing your electronic business card, as well as access to unique internet services.</p>

            <p>Our products and services are designed to help you stand out among competitors by providing tools to create amazing electronic business cards that reflect your professionalism and uniqueness.</p>

            <p>We provide user-friendly platforms and functional tools that enable you to easily create, customize, and distribute your electronic business card in the digital landscape. Our mission is to simplify the process of managing your profile and help you reach new heights in the digital sphere.</p>

            <p>With MAS, your electronic business card becomes more than just an informational resource; it becomes a powerful tool for attracting new connections and opportunities in the online world.</p>
        </div>
      
        <a href="mailto:support@mastag.online" class="support1">
            <span class="email">support</span>
            <span class="email1">@mastag.online</span>
        </a>
    </div>
</template>

<script>
export default{
 
    methods:{
        toProf(){
            this.$router.push('/list');
        }
    }
}
</script>

<style scoped>
.main{
    position: relative;
    overflow-y: scroll;
    background: #FFFFFF;
}

.mas{
    display: inline-block;
    position: relative;
    margin-top: 50px;
}

.text{
    padding: 15px;
    text-align: left;
}
p{
    margin-bottom: 25px;
}

.work{
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    color: #222222;
}

.support1{
    display: block;
    margin-top:20px;
}

.email{
    color:#72D48E;
    font-family: 'Outfit';
    font-size: 21px;
}
.email1{
    color:#363636;
    font-family: 'Outfit';
    font-size: 21px;
}
</style>