<template>
    <div  class="main">
        <div class="mass">
            <icon name="mas"/>
        </div>
        <div class="block" v-if="this.$store.state.user">
            <div class="discription">
                <p class="text">To get started you need to scan your tag</p>
            </div>
            <div class="scan" >
                <p class="tagbutton">Scan the tag</p>
                <icon name="scan" class="scanpic"/>
            </div>
            <div class="support">
                <span class="email">support</span>
                <span class="email1">@mastag.online</span>
            </div>
        </div>
        <div v-else>
            <div class="block2">
                <div>
                    <span class="textforlog">To add a tag, you need to log in</span>
                </div>
                <div class="tolog" @click="toLog">
                    <span class="but">Log In</span>
                </div>
        </div>
        <div class="support1">
                <span class="email">support</span>
                <span class="email1">@mastag.online</span>
            </div>
        </div>
    </div>
</template>

<script>
import icon from '../components/SetIconVue.vue'
export default{
    data(){
        return {
    
        }
    },
    mounted(){
        if(this.$store.state.user){
            this.$cookies.set('name_start', this.$store.state.user._id, {
                domain: 'mastag.online',
                path: '/',  
                secure: true,
                sameSite: 'none',
                expires: 1 
            })
        }
      
    },
    components:{
        icon
    },
    
    methods:{
        toLog(){
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped>
.main{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.mass{
    display: inline-block;
    position: relative;
    margin-top: 70px;
}

.block{
    margin-top: 70px;
    
}
.scan{
    margin-top: 30px;
    padding-top: 20px;
    display: inline-block;
    width: 200px;
    height: 200px;
    background: #F4F4F4;
    border-radius: 10px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 23px;
    text-align: center;
    color: #222222;
}

.scanpic{
    padding-left:35px;
}

.discription{
    margin-bottom: 30px;
}

.text{
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #222222;
}

.block2{
    margin-top: 110px;
}
.textforlog{

    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #222222;
}

.tolog{
    padding-top: 11px;
    font-family: 'Outfit';
    font-style: bold;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #4e4e4e;
    display: inline-block;
    margin-top:80px;
    width: 130px;
    height: 50px;
    background: #F4F4F4;
    border-radius: 8px;
}

.support1{
    display: block;
    margin-top:200px;
}
.support{
    margin-top:50px;
}
.email{
    color:#72D48E;
    font-family: 'Outfit';
    font-size: 21px;
}
.email1{
    color:#363636;
    font-family: 'Outfit';
    font-size: 21px;
}
</style>