<template>
    <div class="main" v-if="this.$store.state.user.role ==='admin' && this.$store.state.user.role.length != null">
        
    </div>
    <div v-else>
        <p class="page">
        <span class="first-half">4</span>
        <span class="second-half">04</span>
        </p>
    
        <p class="page1">
        <span class="third-half">Page n</span>
        <span class="fourth-half">ot found</span>
        </p>
    </div>
</template>

<script>
// import axios from '/src/axios/axios';
export default{
    data(){
        return{
            uid:'',
        }
    },
    // mounted(){
    //     if(!this.$store.state.user){
    //     this.$router.push('/LoginFirst')
    //   }
    // },
   
    methods:{
        create(){
        
        }
    }
}

</script>


<style scoped>
.main{
    position: relative;
    overflow-y: scroll;
    background: #FFFFFF;
}

.h{
    padding-right: 10px;
    width:100%;
    height: 40px;
    background-color: #a09f9f;
}

.admin{
    font-size: 25px;
    text-align: right;
    z-index: 9999;
    color: black;
}

.h2{
    margin-top:15px;
    
}

.firmware{
    font-size: 30px;
}

.block{
    display: inline-block;
    margin-top:20px;
}

.newuid{
    background: #c9c5c5;
    width: 150px;
    display: inline-block;
}
.new{
    font-size: 20px;
}

.uuidv{
    display: inline-block;
    width: 320px;
    height: 48px;
    margin-top: 20px;
    background: #c9c5c5;
}

.write{
    display: inline-block;
    margin-top: 20px;
    background: #c9c5c5;
    width: 150px;
    height: 40px;
    align-items: center;
}

.writenew{
    font-size: 30px;
}

.line{
    
    margin-top: 60px;
    border-top: 1px solid black;
    
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
}

.block2{
    display: inline-block;
    margin-top:20px;
}

.read{
    margin-top: 40px;
    display: inline-block;
}
.readnew{
    font-size: 30px;
    background: #c9c5c5;
    width: 150px;
    height: 40px;
    align-items: center;
}


.page{
  margin-top:350px;
}
.first-half{
  margin-top:350px;
  color:#72D48E;
  font-family: 'Gemunu Libre';
font-style: swap;
font-weight: 700;
font-size: 90px;
line-height: 28px;
position: relative;
align-items: center;
text-align: center;

padding-bottom: 15px;
}

.second-half{
  margin-top:350px;
  color:#363636;
  font-family: 'Gemunu Libre';
font-style: swap;
font-weight: 700;
font-size: 90px;
line-height: 28px;
position: relative;
align-items: center;
text-align: center;

padding-bottom: 15px;
}

.page1{
  margin-top:25px;
}

.fourth-half{
  font-family: 'Gemunu Libre';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 28px;
position: relative;
align-items: center;
text-align: center;
color:#72D48E;
padding-bottom: 15px;
}

.third-half{
  font-family: 'Gemunu Libre';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 28px;
position: relative;
align-items: center;
text-align: center;

padding-bottom: 15px;
}
</style>