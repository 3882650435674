<template>
    <div>
        <p class="page">
          <span class="first-half">4</span>
          <span class="second-half">04</span>
        </p>
        
        <p class="page1">
          <span class="third-half">Page n</span>
          <span class="fourth-half">ot found</span>
        </p>
    </div>
</template>

<style scoped>
.page{
  margin-top:350px;
}
.first-half{
  margin-top:350px;
  color:#72D48E;
  font-family: 'Gemunu Libre';
  font-style: swap;
  font-weight: 700;
  font-size: 90px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  padding-bottom: 15px;
}

.second-half{
  margin-top:350px;
  color:#363636;
  font-family: 'Gemunu Libre';
  font-style: swap;
  font-weight: 700;
  font-size: 90px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  padding-bottom: 15px;
}

.page1{
  margin-top:25px;
}

.fourth-half{
  font-family: 'Gemunu Libre';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  color:#72D48E;
  padding-bottom: 15px;
}

.third-half{
  font-family: 'Gemunu Libre';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  position: relative;
  align-items: center;
  text-align: center;
  padding-bottom: 15px;
}
</style>