<template>
  <div class="wrapper">
    <div v-if="loading" class="preloader"></div>
    <div v-else class="profile">
      <div class="url">
        <div class="input">
          <a class="forUrl" :href="`/tag/${arr.short_url || arr.url}`" target="_blank">
            mastag.online/tag/{{arr.short_url || arr.url }}
          </a>
        </div>
        <div class="copyBtn">
          <img class="copy" @click="copy" src="../assets/img/share.svg">
        </div>
      </div>
      <div class="block">
        <div class="photo">
          <img class="mainphoto" v-if="arr.photo" :src="arr.photo" alt="photo" @click="openPhotoSelector">
          <img class="mainphoto" v-else src="../assets/img/default.svg" alt="photo" @click="openPhotoSelector">
          <input type="file" accept="image/*" ref="photoInput" style="display: none" @change="handlePhotoSelect">
        </div>
        <div class="description">
          <div class="name" @click="openModal">
            <p class="profileName">{{ arr.name }}</p>
            <div class="edit">
              <img class="changeName" src="../assets/Profile/Edit.svg" alt="edit">
            </div>
          </div>
          <div class="about">
            <p class="aboutProfile" v-html="arr.description"></p>
          </div>
        </div>
        <div class="buttons">
          <!-- <button @click="buy" class="buy">Buy tags</button> -->
          <button @click="addNew" class="addNewLinks">
            <icon name="plus" style="height: auto;width: auto;" />
          </button>
        </div>
      </div>
      <div v-if="arr.links && Object.keys(arr.links).length" class="block1">
        <div v-for=" (value, key ) in arr.links " :key="key" class="social" @click="myLink(arr.links[key], key)">
          <icon :name="key" />

          <div class="textfor">
            <p class="labelSocial">{{ value.name }}</p>
          </div>
        </div>
      </div>
      <a-empty v-else />
    </div>

    <Modal :show_dialog="showDialog" :arr="arr" :stringtag="stringtag" @close="showDialog = false" @save-complete="actionSuccess" />
    <MyLinkModal :show_dialog="showDialog1" :arr="arr" :linkfor="linkfor" :namefor="namefor" :keyobj="keymodal"
      :dataobj="objectmodal" @close="showDialog1 = false" @deleteComplete="actionSuccess" @save-complete="actionSuccess" />
  </div>
</template>

<script>
import {message} from 'ant-design-vue'
import Modal from '../components/TitleModal.vue'
import MyLinkModal from '../components/MyLinkModal.vue'
import axios from '/src/axios/axios';
import icon from '../components/SetIconVue.vue'
// import {Base64} from 'js-base64';
export default {

  components: {
    Modal, // модалка для изменения имени
    MyLinkModal, // модалка с соц сетями 
    icon
  },

  data() {
    return {
      loading: true,
      showDialog: false,
      showDialog1: false,
      socialData: null,
      arr: [], //  для даты меток,
      stringtag: '',
      links: '',
      objectmodal: null,
      keymodal: null,
      titleFor: '',
      namefor: '',
      linkfor: '',
      photo: "../assets/img/default.svg", // Путь к фото по умолчанию
      selectedImage: null, // Ссылка на выбранное изображение
      uint8Array: ''
    }
  },
  mounted() {
    const selectedImage = this.arr.photo;
    // Если есть Base64-строка, преобразуем ее в URL объект
    if (!selectedImage) {
      this.photo = require("../assets/img/default.svg"); // Путь к дефолтной картинке из конфига
    }

    if (!this.$store.state.user) {
      this.$router.push('/login')
    }
    this.list();
    this.stringtag = Object.values(this.$route.query).join('')
  
    if (!this.stringtag) {
      this.$router.push('/list')
    }
  },

  methods: {
    async copy() {
      const shareData = {
        title: this.arr.name,
        text: this.arr.description,
        url: '/tag/' + this.arr.url,
      };
      await navigator.share(shareData)
        .then(() => {
          console.log(shareData)
        })
        .catch(err => {
          console.error('Не удалось скопировать данные: ', err)
        })
    },

    reloadPage() {
      location.reload()
    },

    addNew() {
      const stringtag = this.$route.query
      console.log(stringtag.tag)
      this.$router.push({
        name: 'Add New Link',
        query: stringtag.tag
      })
    },

    buy() {
      this.$router.push('shop');
    },

    openModal() {
      this.showDialog = true;
    },

    myLink(value, key) {
      this.showDialog1 = true;
      this.objectmodal = value; //для передачи значеия в модалку 
      this.namefor = this.objectmodal.name;
      this.linkfor = this.objectmodal.link;
      this.keymodal = key; //для передачи значеия в модалку 
    },

    list() {
      try{
        axios.post('/api/tag/check/', this.$route.query)
        // отправляю на бэк дату со стора 
        .then(response => {
          this.arr = response.data
          // const binaryData = atob(this.arr.photo);
          // const uint8Array = Uint8Array.from(binaryData, c => c.charCodeAt(0));
          // console.log(uint8Array)
          // const blob = new Blob([uint8Array], { type: 'image/png' }); // Укажите правильный тип изображения, если он отличается

          // Создаем URL объект из Blob
          // this.selectedImage = URL.createObjectURL(blob);

          this.loading = false
        })
        .catch(err => {
          console.error(err)
        })
      }
      catch(err){
        console.error(err)
      }
    },

    actionSuccess(){
      message.success('Success!')
      this.list()
    },

    openPhotoSelector() {
      const input = this.$refs.photoInput
      input.click()
    },

    handlePhotoSelect(event) {
      let url = this.arr.url
      const file = event.target.files[0]
      if (file) {
        let formData = new FormData()
        formData.append('image', file)
        formData.append('url', url)
        this.uploadImage(formData)
      }
    },

    async uploadImage(formData) {
      try{
        await axios.post('/api/tag/changephoto', formData)
        message.success('Success!')
        this.reloadPage()
      }
      catch(err){
        message.error('Error!')
        console.error(err)
      }
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.profile {
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}

.back {
  position: absolute;
  left: 30px;
  top: 2px;
}

.url {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15.5px;
}

.input {
  position: relative;
}

.copyBtn {
  position: absolute;
  right:30px;
  z-index: 9;
  padding: 0;
  top: 3px;
}

.forUrl {
  position: relative;
  padding: 10px 60px 10px 25px;
  display: block;
  width: 330px;
  background: #F4F4F4;
  border: none;
  border-radius: 5.46512px;
  text-align: left;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block {
  position: relative;
  margin-top: 15.5px;
  margin-bottom: 20px;
}

.mainphoto {
  width: 100%;
}

.description {
  position: relative;
  margin-top: 15px;
}

.profileName {
  display: inline-block;
  margin-bottom: 2px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
}

.aboutProfile {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #000000;
}

.photo {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.pencil {
  position: absolute;
  top: 110px;
}

.edit {
  position: relative;
  display: inline-block;
}

.changeName {
  position: absolute;
  left: 9px;
  bottom: 1px;
  display: inline-block;
}

.buttons {
  margin-top: 18px;
  display: flex;
  padding: 0 30px;
}

.buy {
  position: relative;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #222222;
}

.addNewLinks {
  width: 100%;
  position: relative;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.block1 {
  margin-top: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 15px 0 15px;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  background: #F4F4F4;
  border-radius: 7px;
  padding: 10px 15px 20px 15px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  overflow: hidden;
  margin-bottom: 13px;
}

.social:nth-child(3n+1){
  margin-left: 0;
}

.social:nth-child(3n){
  margin-right: 0;
}

.pic {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
}

.textfor {
  position: relative;
  top: 10px;
}

.labelSocial {
  align-self: flex-end;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-size: 12.95px;
  line-height: 16px;
  text-align: center;
  color: #222222;
}

.icon {
  text-align: center;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>