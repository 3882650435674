<template>
  <div>
    <div class="main">
      <div class="h">
        <img class="mas" src="../assets/MAS.svg">
      </div>
      <div class="block">
        <p class="text">Enter your email to change your password.</p>
        <div class="inputblock"></div>
        <a-alert
          v-if="serverResponse === false"
          description="User with this email not found."
          type="error"
          show-icon
        />
        <a-alert
          v-if="serverResponse === true"
          description="A password recovery email has been sent to the mail."
          type="success"
          show-icon
        />
        <div class="inp1">
          <input id="email" class="inp" v-model="email" placeholder="Email">
        </div>
      </div>
      <div class="button">
        <button @click="send" class="btn">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '/src/axios/axios';
export default{
  data(){
    return{
      email:'',
      serverResponse:''
    }
  },
  methods:{
    send(){
      axios.post('/api/user/activate',{ reason: 'resetPass', data: { email: this.email }})
      .then(response => {
        if(response.data === false){
          this.serverResponse = false
        } else{
          this.serverResponse = true
        }
      })
    }
  }
}
</script>

<style scoped>
.main{
  position: relative;
  overflow-y: scroll;
  background: #FFFFFF;
}
.h{
  position: relative;
  margin-top: 50px;
  align-items: center;
  
}

.block{
  position: relative;
align-items: center;
margin-top:139px;
}


.text{
  font-family: 'Outfit';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */

text-align: center;

/* 222222 */

color: #222222;
}

.inp{
margin-top:35px;
  width: 325px;
  height:48px ;
  background: #F4F4F4;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  outline: none;
}

.button{
  margin-top:143px;
}

.btn{

width: 173.4px;
height: 45.63px;
background: #363636;
border-radius: 8px;
border:none;
color:#FFFFFF;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
text-transform: capitalize;
box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
}



.btnText{
  position: absolute;
left: 39.2%;
right: 39.2%;
top: 78.6%;
bottom: 16.07%;

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
text-transform: capitalize;

color: #FFFFFF;
}
</style>